import { CheckIcon } from '@heroicons/react/solid';
import { getCheckoutSession } from 'common/api/subscription/session';
import useAuth from 'common/auth/useAuth';
import { ReactElement, useState } from 'react';

const tiers = [
  {
    name: 'SIC Lite',
    priceMonthly: 4.99,
    priceYearly: 49.99,
    subLevel: 'lite',
    description: 'Daily picks and basic game data',
    includedFeatures: [
      'See up to 5 Daily Picks for all major sports',
      'Use basic game data to refine your decisions',
    ],
  },
  {
    name: 'SIC PRO',
    priceMonthly: 7.49,
    priceYearly: 74.99,
    subLevel: 'pro',
    description: 'Full game insight and recommended picks',
    includedFeatures: [
      'Curated Daily Picks, organized by sport',
      'Access to data for all games',
      'Full betting data, visualized in a clear format',
      "Easy-to-use filters to get a good idea of today's games at a glance",
    ],
  },
];

export default function PricingPanel(): ReactElement {
  const auth = useAuth();
  const [monthly, setMonthly] = useState(true);

  async function openCheckout(subLevel: string, subDuration: string) {
    const session = await getCheckoutSession(subLevel, subDuration, auth.user);

    window.location.href = session.url;
  }

  return (
    <div className="w-full max-w-screen-lg md:mx-auto py-6 md:py-24 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-5xl tracking-tight font-bold text-gray-900 sm:text-center">
          Sports Investing Club
        </h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Get access to game and betting data now.
        </p>
        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8 border-2 border-gray-200 rounded-lg">
          <button
            type="button"
            className={`${
              monthly ? 'bg-white' : ''
            } relative w-1/2 border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8`}
            onMouseDown={() => {
              setMonthly(true);
            }}
          >
            Monthly billing
          </button>
          <button
            type="button"
            className={`${
              !monthly ? 'bg-white' : ''
            } ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8`}
            onMouseDown={() => {
              setMonthly(false);
            }}
          >
            Yearly billing
          </button>
        </div>
      </div>
      <div className="mt-8 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2">
        {tiers.map((tier) => (
          <div
            key={tier.name}
            className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white"
          >
            <div className="p-6 pb-4">
              <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
              <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
              <p className="mt-8">
                <span className="text-4xl tracking-tight font-bold text-gray-900">
                  ${monthly ? tier.priceMonthly : tier.priceYearly}
                </span>{' '}
                <span className="text-base font-medium text-gray-500">
                  {monthly ? '/mo' : '/yr'}
                </span>
              </p>
              <a
                className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 cursor-pointer"
                onClick={() => openCheckout(tier.subLevel, monthly ? 'monthly' : 'yearly')}
              >
                Buy {tier.name}
              </a>
              {auth.user?.free_trial_eligible && (
                <div className="text-center w-full mt-3 text-sm">
                  Start today with a 14-Day Free Trial
                </div>
              )}
            </div>

            <div className="pt-6 pb-8 px-6">
              <h3 className="text-sm font-medium text-gray-900">What&apos;s included</h3>
              <ul role="list" className="mt-6 space-y-4">
                {tier.includedFeatures.map((feature) => (
                  <li key={feature} className="flex space-x-3">
                    <CheckIcon
                      className="flex-shrink-0 h-5 w-5 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
