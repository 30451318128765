import { ReactElement } from 'react';

import { LoaderContainer, LoaderWrapper, LargeLoaderWrapper } from './Loader.styles';

interface Props {
  large?: boolean;
  theme?: string;
}

export function Loader({ theme = 'slate', large = false }: Props): ReactElement {
  return (
    <>
      {!large && (
        <LoaderContainer>
          <LoaderWrapper theme={theme}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </LoaderWrapper>
        </LoaderContainer>
      )}
      {large && (
        <LoaderContainer>
          <LargeLoaderWrapper theme={theme}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </LargeLoaderWrapper>
        </LoaderContainer>
      )}
    </>
  );
}
