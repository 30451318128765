import { ReactElement } from 'react';

import { StyledBox, StyledColorBox } from './ColorBox.styles';

interface Props {
  value: number;
  description?: string;
}

export default function ColorBox({ value, description = '' }: Props): ReactElement {
  const opacity = Math.abs(value) / 10;
  const color = value < 0 ? `rgba(17, 84, 242, ${opacity});  ` : `rgba(242, 103, 17, ${opacity})`;

  return (
    <StyledBox>
      <StyledColorBox background={color} text={opacity > 0.5 ? 'white' : 'black'}>
        {value.toFixed(1)} {description && '(' + description + ')'}
      </StyledColorBox>
    </StyledBox>
  );
}
