import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import DeltaTable from 'components/features/deltatable';

export default function NcaabRanks(): ReactElement {
  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-lg mt-5">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-5 leading-normal">
            NCAAB Top 25 Rank Deltas
          </h2>
          <DeltaTable />
        </div>
      </div>
    </FullLayout>
  );
}
