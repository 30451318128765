import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import useAuth from 'common/auth/useAuth';

import { TailwindTextInput } from 'components/elements/textinput';

interface LocationState {
  from: {
    pathname: string;
  };
  login: boolean;
}

export default function LoginMask(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [error, setError] = useState(false);

  const [from, setFrom] = useState('');

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (auth.error && !auth.loading) {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
    }
  }, [auth]);

  useEffect(() => {
    const locationState = location.state as LocationState;
    if (locationState) {
      setFrom(locationState.from?.pathname || '/');
    }
  }, [location]);

  useEffect(() => {
    if (!login || !password) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [login, password]);

  async function handleLogin() {
    setLoading(true);
    auth.login(login, password);
  }

  const handleKeypress = (event: any) => {
    if (event.key == 'Enter') {
      handleLogin();
    }
  };

  return (
    <>
      <div className="h-full flex flex-col bg-white w-full lg:w-auto">
        <div className="mt-12 px-4 sm:px-6 lg:flex-none lg:mt-24 lg:px-20 xl:px-24">
          <img className="h-20 w-auto" src="/LogoSlate.png" alt="Sports Investing Club Logo" />
        </div>
        <div className="flex-1 flex flex-col w-full py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Sign in to
                <br /> your account
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{' '}
                <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                  <span>register a new account now!</span>
                </Link>
              </p>
            </div>

            <div className="mt-8 mb-12">
              <div className="mt-6">
                <div className="space-y-6">
                  <TailwindTextInput
                    className="col-span-6 sm:col-span-3"
                    id="login"
                    label="Login"
                    handleKeypress={handleKeypress}
                    handleChange={(event) => {
                      setError(false);
                      setLogin(event.target.value);
                    }}
                    value={login}
                  />

                  <TailwindTextInput
                    className="col-span-6 sm:col-span-3"
                    type="password"
                    id="password"
                    label="Password"
                    error={error}
                    errormessage="Password incorrect"
                    handleKeypress={handleKeypress}
                    handleChange={(event) => {
                      setError(false);
                      setPassword(event.target.value);
                    }}
                    value={password}
                  />

                  <div className="flex items-center justify-center">
                    <div className="text-sm">
                      <Link
                        to="/forgot-password"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot your password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      name={'log in'}
                      disabled={!canSubmit || loading}
                      onClick={() => {
                        handleLogin();
                      }}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-xs text-center font-medium underline text-slate-700">
              <div className="flex justify-center mb-2 mt-2">
                <a
                  target="_blank"
                  href="https://sportsinvestingclub.com/terms-of-service"
                  rel="noreferrer"
                  className="mr-1"
                >
                  Terms of Service
                </a>
                <a
                  target="_blank"
                  href="https://sportsinvestingclub.com/privacy"
                  rel="noreferrer"
                  className="ml-1"
                >
                  Privacy Policy
                </a>
              </div>
              <a href="mailto:help@sportsinvestingclub.com">Need help?</a>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
