import { css, cx } from '@emotion/css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { md5 } from 'hash-wasm';

import { Sports, SubscriptionLevels } from 'models/enums';

import { MenuAlt3Icon, LogoutIcon, XIcon } from '@heroicons/react/outline';

import useAuth from 'common/auth/useAuth';
import useSub from 'common/provider/SubscriptionProvider';

export function MobileNav() {
  const [userGravatarHash, setUserGravatarHash] = useState('');
  const [open, setOpen] = useState(false);

  const auth = useAuth();
  const sub = useSub();

  const navItems = [
    {
      title: 'Sports',
      items: [
        { to: '/', title: 'Dashboard', children: [], display: true },
        {
          key: 'mlb',
          to: '/mlb',
          title: 'MLB',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.MLB.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/mlb',
              title: 'Games',
              display: true,
            },
          ],
        },
        {
          key: 'ncaaf',
          to: '/ncaaf',
          title: 'NCAAF',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NCAAF.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/ncaaf',
              title: 'Games',
              display: true,
            },
          ],
        },
        {
          key: 'nfl',
          to: '/nfl',
          title: 'NFL',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NFL.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/nfl',
              title: 'Games',
              display: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Off-Season',
      display: auth.user && (sub.ALL.level == SubscriptionLevels.PRO || auth.user.user_level >= 4),
      items: [
        {
          key: 'nba',
          to: '/nba',
          title: 'NBA',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NBA.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/nba',
              title: 'Games',
              display: true,
            },
          ],
        },
        {
          key: 'ncaab',
          to: '/ncaab',
          title: 'NCAAB',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NCAAB.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/ncaab',
              title: 'Games',
              display: true,
            },
            {
              to: '/ncaab/ranks',
              title: 'Rank Deltas',
              display: auth.user?.user_level && 5 <= auth.user?.user_level,
            },
            {
              to: '/ncaab/top-25',
              title: 'Top 25',
              display: true,
            },
          ],
        },
        {
          key: 'nhl',
          to: '/nhl',
          title: 'NHL',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NHL.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/nhl',
              title: 'Games',
              display: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Tutorials & FAQ',
      display: auth.user && auth.user.user_level >= 5,
      items: [{ to: '/tutorials', title: 'SIC Tutorials', children: [], display: true }],
    },
    {
      title: 'Administration',
      display: auth.user && auth.user.user_level >= 5,
      items: [
        { to: '/invite', title: 'Invite', children: [], display: true },
        {
          to: '/users',
          title: 'Users',
          display: auth.user?.user_level && 10 <= auth.user?.user_level,
          children: [],
        },
        {
          to: '/data',
          title: 'Data',
          display: auth.user?.user_level && 10 <= auth.user?.user_level,
          children: [],
        },
      ],
    },
  ];

  useEffect(() => {
    getUserGravatarHash();
  }, []);

  async function getUserGravatarHash() {
    if (auth.user) {
      await md5(auth.user.user_email.toLowerCase()).then((data) => {
        setUserGravatarHash(data);
      });
    }
  }

  return (
    <div className="w-full md:hidden max-h-full">
      <div className="flex border-b bg-white px-4 py-4 text-slate-800 justify-between items-center">
        <Link className="flex items-center" to="/profile">
          <div className="rounded-full h-10 w-10 border-2 border-slate-500 shadow mr-3">
            <img
              className={css`
                position: relative;
                top: -2px;
                left: -2px;
                height: 40px;
                width: 40px;
                max-width: 40px;
                border-radius: 20px;
              `}
              src={`https://www.gravatar.com/avatar/${userGravatarHash}`}
            />
          </div>
          <div className="flex flex-wrap">
            {auth.user && auth.user.subscriptions.find((sub) => sub.sport_id == Sports.ALL) && (
              <>
                <span className="min-w-full h-0 pt-1">&nbsp;</span>
                <div
                  className={`mb-0.5 text-xxs text-white px-1 py-0.5 rounded-sm ${
                    sub.ALL.level == SubscriptionLevels.PRO ? 'bg-orange-700' : 'bg-sky-700'
                  }`}
                >
                  {sub.ALL.level == SubscriptionLevels.PRO ? 'PRO' : 'LITE'}
                </div>
              </>
            )}
            <span className="font-medium w-full">{auth.user?.username}</span>
          </div>
        </Link>
        <div className="text-sm">
          {!open && (
            <MenuAlt3Icon
              className="h-8 w-8"
              onClick={() => {
                setOpen(true);
              }}
            />
          )}
          {open && (
            <XIcon
              className="h-8 w-8"
              onClick={() => {
                setOpen(false);
              }}
            />
          )}
        </div>
      </div>
      <div
        className={`${
          open ? 'overflow-auto' : 'max-h-0 overflow-hidden pt-0 pb-0'
        } absolute w-full bg-white z-10 transition px-4 py-4 shadow box-border`}
      >
        {navItems.map((section) => {
          return (
            section.display !== false && (
              <>
                <h3 key={section.title} className="text-gray-400 text-xs ml-3 mb-2 mt-4">
                  {section.title}
                </h3>
                {section.items.map((item) => {
                  return (
                    item.display !== false && (
                      <div key={section.title + item.title}>
                        <Link
                          key={item.to + item.title}
                          to={item.to}
                          className={`${
                            location.pathname === item.to ||
                            (item.children &&
                              item.children.length > 0 &&
                              location.pathname.includes(item.to))
                              ? 'bg-slate-100'
                              : 'bg-white'
                          } flex justify-between p-3 rounded`}
                        >
                          <div className="flex items-center">
                            <span className="font-medium">{item.title}</span>
                            {item.children ? (
                              <span
                                className={`${
                                  !location.pathname.includes(item.to) &&
                                  item.to !== location.pathname
                                    ? 'hidden'
                                    : ''
                                } text-xs ml-2 text-gray-600`}
                              >
                                {item.children && item.children.length > 0 && (
                                  <>
                                    <span className="mr-1">-</span>
                                    <span className="ml-1">
                                      {
                                        item.children.find((obj) => obj.to === location.pathname)
                                          ?.title
                                      }
                                    </span>
                                  </>
                                )}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </Link>
                        {item.children &&
                          item.children.filter((i) => {
                            return i.display !== false;
                          }).length > 1 &&
                          item.children?.map((subItem) => {
                            return (
                              subItem.display !== false && (
                                <Link
                                  key={subItem.to + subItem.title}
                                  to={subItem.to}
                                  className={`${
                                    location.pathname == subItem.to ? 'bg-slate-100' : 'bg-white'
                                  } flex justify-between p-3 rounded pl-5 font-medium mt-1`}
                                >
                                  {subItem.title}
                                </Link>
                              )
                            );
                          })}
                      </div>
                    )
                  );
                })}
              </>
            )
          );
        })}
        <div
          onClick={() => {
            auth.logout();
          }}
          className={`mt-6 font-medium flex justify-between p-3 items-center rounded`}
        >
          <span>Logout</span>
          <LogoutIcon className="w-5 h-5" />
        </div>
      </div>
    </div>
  );
}
