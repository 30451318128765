import { User } from 'models/user';
import { getCurrentGameData, getGameData } from '../../generic/genericDownload';

export async function getNbaGameData(user?: User) {
  getGameData('nba', user);
}

export async function getCurrentNbaGameData(user?: User) {
  getCurrentGameData('nba', user);
}
