import { User } from 'models/user';
import { authorizedApiCall } from 'common/api/apiCall';
import { Invite } from 'models/invite';

export async function createInvite(
  email: string,
  inviteLevel: string,
  user: User
): Promise<Invite> {
  const options = {
    method: 'POST',
  };

  const res = await authorizedApiCall(
    `/invite?email=${email}&invite_level=${inviteLevel}`,
    options,
    user
  );

  if (res.error) {
    return { invite_hash: '0' } as Invite;
  }

  return res.data;
}

export async function getInvites(user?: User): Promise<Array<Invite>> {
  if (!user) {
    return [];
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/invite`, options, user);

  if (res.error) {
    return [];
  }

  return res.data;
}

export async function deleteInvite(user?: User, invite?: Invite): Promise<Array<Invite>> {
  if (!user || !invite) {
    return [];
  }

  const options = {
    method: 'DELETE',
  };

  const res = await authorizedApiCall(
    `/invite?email=${invite.user_email}&invite_hash=${invite.invite_hash}`,
    options,
    user
  );

  if (res.error) {
    return [];
  }

  return res.data;
}
