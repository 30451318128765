import styled from '@emotion/styled';

interface ButtonProps {
  disabled?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  text-transform: uppercase;
  background-color: var(--color-purple);
  border: none;
  border-radius: 0.4rem;
  padding: 1.4rem 2.8rem;
  color: white;
  font-weight: 700;
  font-size: 1.4rem;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};

  &:hover {
    background-color: var(--color-purpler);
    cursor: pointer;
  }
`;
