import { ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from 'common/auth/useAuth';

import { requestReset } from 'common/auth/reset';
import { TailwindTextInput } from 'components/elements/textinput';

export default function ForgotPasswordMask(): ReactElement {
  const navigate = useNavigate();
  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [error, setError] = useState(false);

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (auth.error && !auth.loading) {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
    }
  }, [auth]);

  useEffect(() => {
    if (!email) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [email]);

  async function handleResetRequest() {
    setLoading(true);
    const res = await requestReset(email);
    const data = (await res.data) as any;
    if (res.error) {
      setError(true);
      setLoading(false);
    } else if (data.success) {
      setLoading(false);
      setError(false);
      setSuccess(true);
    }
  }

  const handleKeypress = (event: any) => {
    if (event.key == 'Enter') {
      handleResetRequest();
    }
  };

  return (
    <>
      {success ? (
        <div className="h-full flex flex-col bg-white w-full lg:w-auto">
          <div className="mt-12 px-4 sm:px-6 lg:flex-none lg:mt-24 lg:px-20 xl:px-24">
            <img className="h-20 w-auto" src="/LogoSlate.png" alt="Sports Investing Club Logo" />
          </div>
          <div className="flex-1 flex flex-col w-full py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
            <div className="mx-auto w-full lg:w-96">
              <div>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Reset
                  <br /> your password
                </h2>
                <p className="my-6">Success - Password reset link sent to your inbox!</p>
                <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                  <span>Back to login</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full flex flex-col bg-white w-full lg:w-auto">
          <div className="mt-12 px-4 sm:px-6 lg:flex-none lg:mt-24 lg:px-20 xl:px-24">
            <img className="h-20 w-auto" src="/LogoSlate.png" alt="Sports Investing Club Logo" />
          </div>
          <div className="flex-1 flex flex-col w-full py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
            <div className="mx-auto w-full lg:w-96">
              <div>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Reset
                  <br /> your password
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Or{' '}
                  <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                    <span>log in now!</span>
                  </Link>
                </p>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-6">
                    <TailwindTextInput
                      className="col-span-6 sm:col-span-3"
                      id="email"
                      placeholder="your@email.com"
                      label="Email"
                      error={error}
                      errormessage="Not a valid email address"
                      handleKeypress={handleKeypress}
                      handleChange={(event) => {
                        setError(false);
                        setEmail(event.target.value);
                      }}
                      value={email}
                    />

                    <div>
                      <button
                        name="Reset Password"
                        disabled={!canSubmit || loading}
                        onClick={() => {
                          handleResetRequest();
                        }}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
}
