import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import { Link } from 'react-router-dom';

export default function Selectivity(): ReactElement {
  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-md mt-5">
          <Link to="/tutorials" className="text-sm font-medium">
            Go Back
          </Link>
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate leading-normal mb-6">
            Selectivity and Sports Betting
          </h1>
          <div className="leading-normal">
            <p className="mb-3">
              The SIC (Sports Investing Club) believes that we have an edge in the sports
              marketplace based on various factors.
            </p>
            <p className="mb-3">
              Even so - it is difficult to beat the -110 odds (&quot;bet 11 to win 10&quot;) or
              “vig” that sportsbooks collect.
              <ul className="mt-2 list-disc ml-5">
                <li className="mb-1">
                  Due to the “vig” - <u>you need about a 52% win rate</u> in point spread sports to
                  break even - <i>even if you shop around for the best lines</i> (using at least two
                  sportsbooks).
                </li>
                <li className="mb-1">
                  <i>
                    The numbers show that our methods average about a 53% to 56% success rate over
                    the long term.
                  </i>
                </li>
                <li className="mb-1">
                  <i>
                    This recent <u>2022-23 NFL Season</u> was particularly good for our methods.
                  </i>
                </li>
                <li className="mb-1">
                  <i>
                    Our Members can use our plays as <u>standalone bets</u> or use our methods to
                    help <u>filter their own betting interests or rooting interests.</u>
                  </i>
                </li>
              </ul>
            </p>
            <p className="mb-3">
              <span className="text-lg font-bold underline">Selectivity</span> - because the sports
              betting marketplace is so hard to beat, we suggest that Members be selective in their
              betting. Don&apos;t bet too many games (it really IS difficult to consistently beat
              52%!). AND - please don&apos;t bet a large percentage of your bankroll.
            </p>
            <p className="mb-3">
              <span className="text-lg font-bold underline">SIC Update</span> - Our methods stress
              selectivity! On the other hand, we also{' '}
              <i>
                <u>
                  realize that our Members don&apos;t want systems to sit on the fence all the time!
                </u>
              </i>{' '}
              As such, the team at SIC has worked to ensure that{' '}
              <i>
                SIC will generally aim to give a daily play in every major sport if that sport has
                at least 4 games.
              </i>{' '}
              Of course - if the data really shows NO edge - we won&apos;t give a play.
            </p>
          </div>
        </div>
      </div>
    </FullLayout>
  );
}
