import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

interface SettingsContextType {
  editorMode: boolean;
  toggleEditorMode: () => void;
}

const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType);

export function SettingsProvider({ children }: { children: ReactNode }): JSX.Element {
  const [editorMode, setEditorMode] = useState<boolean>(false);

  useEffect(() => {
    const ls = localStorage.getItem('setting_editor');
    if (ls) {
      setEditorMode(JSON.parse(ls));
    } else {
      setEditorMode(false);
      localStorage.setItem('setting_editor', JSON.stringify(false));
    }
  }, []);

  function toggleEditorMode() {
    localStorage.setItem('setting_editor', JSON.stringify(!editorMode));
    setEditorMode(!editorMode);
  }

  const memoedValue = useMemo(
    () => ({
      editorMode,
      toggleEditorMode,
    }),
    [editorMode]
  );

  return <SettingsContext.Provider value={memoedValue}>{children}</SettingsContext.Provider>;
}

export default function useSettings() {
  return useContext(SettingsContext);
}
