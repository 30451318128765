import { ReactElement, useState } from 'react';

import { updateUserPassword } from 'common/auth/users';

import { TailwindTextInput } from 'components/elements/textinput';
import FormSection from 'components/modules/formsection';
import { CheckCircleIcon } from '@heroicons/react/outline';

export function NewPasswordForm(): ReactElement {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [rPassword, setrPassword] = useState('');

  const [validationErrors, setValidationErrors] = useState<any>({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    setOldPassword('');
    setPassword('');
    setrPassword('');

    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const savePassword = async () => {
    if (password !== rPassword || password == '' || rPassword == '' || oldPassword == '') {
      setValidationErrors({
        oldPassword: oldPassword == '' ? "Password can't be empty" : false,
        password: password == '' ? "Password can't be empty" : false,
        rPassword:
          rPassword == ''
            ? "Repeated Password can't be empty"
            : rPassword !== password
            ? 'Passwords do not match'
            : false,
      });
      setError(true);
    } else if (oldPassword && password && rPassword) {
      setLoading(true);
      setError(false);
      const res = await updateUserPassword(oldPassword, password);
      setError(res.error);
      setValidationErrors(await res.data);
      setLoading(false);

      if (!res.error) {
        setSuccess(true);
        setError(false);
        setValidationErrors({});
        clearForm();
      }
    }
  };

  const handleKeypress = (event: any) => {
    if (event.key == 'Enter' && !error) {
      savePassword();
    }
  };

  return (
    <FormSection
      sectionHeader="Password"
      sectionFlavour="Keeping your account secure by updating your password regularly."
    >
      <div className="px-4 py-5 bg-white sm:p-6">
        <div className="grid grid-cols-6 gap-6">
          <TailwindTextInput
            className="col-span-6 sm:col-span-4"
            id="oldPassword"
            placeholder=""
            label="Old Password"
            type="password"
            handleKeypress={handleKeypress}
            error={!!validationErrors.oldPassword && error}
            errormessage={validationErrors.oldPassword}
            handleChange={(event) => {
              setError(false);
              setOldPassword(event.target.value);
              setValidationErrors({});
            }}
            value={oldPassword}
          />
          <TailwindTextInput
            className="col-span-6 sm:col-span-3"
            id="newPassword"
            placeholder=""
            label="New Password"
            type="password"
            error={!!validationErrors.password && error}
            errormessage={validationErrors.password}
            handleKeypress={handleKeypress}
            handleChange={(event) => {
              setError(rPassword !== '' && event.target.value !== rPassword);
              setPassword(event.target.value);
              setValidationErrors({});
              if (rPassword !== event.target.value) {
                setValidationErrors({
                  rPassword: 'Passwords do not match!',
                });
              }
            }}
            value={password}
          />
          <TailwindTextInput
            className="col-span-6 sm:col-span-3"
            type="password"
            id="repeatNewPassword"
            placeholder=""
            label="Repeat New Password"
            error={!!validationErrors.rPassword && error}
            errormessage={validationErrors.rPassword}
            handleKeypress={handleKeypress}
            handleChange={(event) => {
              setrPassword(event.target.value);
              setError(password !== event.target.value);
              setValidationErrors({});
              if (password !== event.target.value) {
                setValidationErrors({
                  rPassword: 'Passwords do not match!',
                });
              }
            }}
            value={rPassword}
          />
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right flex items-center justify-end sm:px-6">
        {success && (
          <span className="flex items-center text-xs mr-5">
            <CheckCircleIcon className="w-5 h-5 mr-1 text-green-600" />
            Password changed successfully!
          </span>
        )}
        <button
          onClick={savePassword}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading}
        >
          Save Password
        </button>
      </div>
    </FormSection>
  );
}
