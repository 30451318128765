import { User } from 'models/user';
import { authorizedApiCall } from '../apiCall';

export async function getUsers(user?: User): Promise<Array<User>> {
  if (!user) {
    return [];
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/admin/users`, options, user);

  return res.data;
}
