import { Game } from 'models/game';
import { User } from 'models/user';
import { authorizedApiCall } from '../apiCall';

export async function addGameHighlight(
  sport: string,
  game_id: number,
  highlight_side: string,
  highlight_over_under: string,
  highlight_play: string,
  highlight_comment: string,
  user?: User
): Promise<Game> {
  if (!user) {
    return {} as Game;
  }

  const options = {
    method: 'POST',
  };

  const res = await authorizedApiCall(
    `/${sport}/highlight?game_id=${game_id}&highlight_side=${highlight_side}&highlight_over_under=${highlight_over_under}&highlight_play=${highlight_play}&highlight_comment=${highlight_comment}`,
    options,
    user
  );

  return res.data;
}

export async function removeGameHighlight(
  sport: string,
  game_id: number,
  user?: User
): Promise<Game> {
  if (!user) {
    return {} as Game;
  }

  const options = {
    method: 'DELETE',
  };

  const res = await authorizedApiCall(`/${sport}/highlight?game_id=${game_id}`, options, user);

  return res.data;
}
