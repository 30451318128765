import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import { Link } from 'react-router-dom';

export default function BettingPercentages(): ReactElement {
  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-md mt-5">
          <Link to="/tutorials" className="text-sm font-medium">
            Go Back
          </Link>
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate leading-normal mb-6">
            Point Spreads versus Moneylines
          </h1>
          <div className="leading-normal">
            <p className="mb-3">
              When talking with friends and members, they want to understand a bit more about what
              we look at. The good thing is that in Point Spread sports, the point spread works in a
              way that most people understand. This is as opposed to Moneyline sports (like the NHL
              and MLB) - where you need a strong sense of probability and odds.
            </p>
            <p className="mb-3">
              SO - in the NBA - we ask our Members to login to their accounts. This evening, there
              are several games where the Betting Percentages flag potential value. In particular,
              we highlight the Thunder.{' '}
              <u>
                <i>About 25% (only 1 in 4!) of bets are on the Thunder.</i>
              </u>
            </p>
            <p className="mb-3">
              In point spread sports, when the marketplace goes to this type of extreme, there is
              generally value on the team with 25% of the bets or so. Our members can see other
              games flagged by our systems.
            </p>
            <p className="mb-3">
              The Thunder ALSO has the benefit of having{' '}
              <u>
                <i>more than half of the Money (Money %) bet on them.</i>
              </u>{' '}
              Thus - with only about 25% of the number of bets on them, but more than half of the
              actual dollars on them - we like the Thunder. More specifically:
            </p>
            <p className="mb-3">
              <ul className="list-disc ml-5">
                <li className="mb-1">
                  The Public does NOT like the Thunder (only 25% of the bets).
                </li>
                <li className="mb-1">
                  But the bigger, smart money likes the Thunder. This is the reason why more than
                  half of the actual dollars bet is on the Thunder.
                </li>
                <li className="mb-1">
                  The research shows that we should fade (bet against) the Public - and follow the
                  Smart Money.
                </li>
              </ul>
            </p>
            <p className="mb-3">
              Please let us know if this helps; and please send us any questions!{' '}
              <i>Thank you for being a part of the Sports Investing Club!</i>
            </p>
          </div>
        </div>
      </div>
    </FullLayout>
  );
}
