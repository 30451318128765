import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import { Link } from 'react-router-dom';

export default function PointSpreads(): ReactElement {
  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-md mt-5">
          <Link to="/tutorials" className="text-sm font-medium">
            Go Back
          </Link>
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate leading-normal mb-6">
            Point Spreads versus Moneylines
          </h1>
          <div className="leading-normal">
            <p className="mb-3">
              Sports fans are generally focused on point spreads. This is definitely true in the NFL
              and college football. Also in the NBA and March Madness. All of these sports have one
              main thing in common. These are <b>point spread sports</b>: meaning that to even
              things up, the sportsbooks and oddsmakers use point spreads to try to even the money
              take on each side. For instance, at the time of this writing, the Eagles are favored
              by 1.5 points over the Chiefs.
            </p>
            <p className="mb-3">
              Sports with enough scoring use point spreads to equalize things. However, certain
              sports <u>don&apos;t have enough scoring to allow point spreads to work well.</u>{' '}
              <b>
                <i>
                  In these sports - such as MLB and the NHL, the oddsmakers use probabilities or
                  moneylines to equalize things.
                </i>
              </b>
            </p>
            <p className="mb-3">
              For instance, a good team like the <b>Los Angeles Dodgers</b> against a weaker team
              may have posted odds such as -180 / +160. This means that you need to bet in a ratio
              of 180 to win 100 if you take the favorite. If you bet on the underdog, you bet 100 to
              win 160. <b>The odds are always keyed off of “100.”</b>
            </p>
            <p className="mb-3">
              You might ask: why is there a difference between the 180 and 160? This is the “vig” or
              commission that the sportsbooks collect to earn a profit and to help them manage risk.
            </p>
          </div>
        </div>
      </div>
    </FullLayout>
  );
}
