import { ReactElement } from 'react';
import { StyledButton } from './Button.styles';

interface Props {
  name: string;
  className?: string;
  disabled?: boolean;
  onClick: (event: any) => void;
}

export default function Button({
  name,
  disabled = false,
  className,
  onClick,
}: Props): ReactElement {
  return (
    <StyledButton className={className} disabled={disabled} name={name} onClick={onClick}>
      {name}
    </StyledButton>
  );
}
