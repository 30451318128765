import { getDailyPicks } from 'common/api/picks/picks';
import useAuth from 'common/auth/useAuth';
import GameCarousel from 'components/modules/gamecarousel';
import { ReactElement, useEffect, useState } from 'react';

const leagues = {
  active: [
    {
      header: 'MLB',
      key: 'mlb',
      gameSpan: 'Day',
      spreadSport: false,
    },
    {
      header: 'NCAAF',
      key: 'ncaaf',
      gameSpan: 'Week',
      spreadSport: true,
    },
    {
      header: 'NFL',
      key: 'nfl',
      gameSpan: 'Week',
      spreadSport: true,
    },
  ],
  inactive: [
    {
      header: 'NBA',
      key: 'nba',
      gameSpan: 'Day',
      spreadSport: true,
    },
    {
      header: 'NCAAB',
      key: 'ncaab',
      gameSpan: 'Day',
      spreadSport: true,
    },
    {
      header: 'NHL',
      key: 'nhl',
      gameSpan: 'Day',
      spreadSport: false,
    },
  ],
};

export function PicksPanel(): ReactElement {
  const auth = useAuth();
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [picks, setPicks] = useState({} as any);

  function loadGames() {
    getDailyPicks(auth.user).then((data) => {
      setPicks(data);
      setDashboardLoading(false);
    });
  }

  useEffect(() => {
    loadGames();
  }, []);

  return (
    <>
      <div className="flex flex-wrap">
        {leagues.active.map((league) => {
          return (
            <div className="w-full xl:w-1/2 2.5xl:w-1/3 mb-4" key={league.key}>
              <div className="shadow overflow-hidden sm:rounded-md bg-white pt-3 xl:mr-2">
                <h3 className="text-lg font-bold leading-7 text-gray-900 sm:text-xl px-4 sm:truncate mb-2 leading-normal flex items-center">
                  {league.header}{' '}
                  <span className="text-sm font-normal ml-1">- Picks of the {league.gameSpan}</span>
                </h3>
                <div className={`flex-1 min-h-max`}>
                  <GameCarousel
                    spreadSport={league.spreadSport}
                    showLoader={dashboardLoading}
                    games={picks[league.key] ? picks[league.key] : []}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-wrap mt-5">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl w-full sm:truncate mb-5 leading-normal">
          Off-Season
        </h2>
        {leagues.inactive.map((league) => {
          return (
            <div className="w-full xl:w-1/2 2.5xl:w-1/3 mb-4" key={league.key}>
              <div className="shadow overflow-hidden sm:rounded-md bg-white pt-3 xl:mr-2">
                <h3 className="text-lg font-bold leading-7 text-gray-900 sm:text-xl px-4 sm:truncate mb-2 leading-normal flex items-center">
                  {league.header}{' '}
                  <span className="text-sm font-normal ml-1">- Picks of the Day</span>
                </h3>
                <div className={`flex-1 min-h-max`}>
                  <GameCarousel
                    spreadSport={league.spreadSport}
                    showLoader={dashboardLoading}
                    games={picks[league.key] ? picks[league.key] : []}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
