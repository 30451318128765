import { AuthenticatedRoute, AuthProvider } from 'common/auth/useAuth';

import { Sports, SubscriptionLevels } from 'models/enums';

import { Dashboard, LoginPage, RegisterPage, NotFound, Invite, Users, Profile } from 'pages';
import MlbGames from 'pages/mlb/games';
import NbaGames from 'pages/nba/games';
import NflGames from 'pages/nfl/games';
import NcaabRanks from 'pages/ncaab/delta';
import NcaabGames from 'pages/ncaab/games';
import NhlGames from 'pages/nhl/games';
import NcaafGames from 'pages/ncaaf/games';
import { ForgotPasswordPage } from 'pages/forgotpassword';
import { ResetPasswordPage } from 'pages/resetpassword';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SubscriptionProvider } from 'common/provider/SubscriptionProvider';
import Data from 'pages/admin/data';
import Welcome from 'pages/welcome';
import { SettingsProvider } from 'common/provider/SettingsProvider';
import NcaabTop25 from 'pages/ncaab/top-25';
import Status from 'pages/admin/status';
import Tutorials from 'pages/tutorials';
import PointSpreads from 'pages/tutorials/pointspreads';
import BettingPercentages from 'pages/tutorials/bettingpercentage';
import Selectivity from 'pages/tutorials/selectivity';

export const BASE_URL = process.env.REACT_APP_API_HOST;

function Router() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/invite"
        element={
          <AuthenticatedRoute accessLevel={5}>
            <Invite />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/status"
        element={
          <AuthenticatedRoute accessLevel={5}>
            <Status />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <AuthenticatedRoute accessLevel={10}>
            <Users />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/data"
        element={
          <AuthenticatedRoute accessLevel={10}>
            <Data />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthenticatedRoute>
            <Profile />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/nba/*"
        element={
          <AuthenticatedRoute subscription={Sports.NBA} subscription_level={SubscriptionLevels.PRO}>
            <NbaGames />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/nhl/*"
        element={
          <AuthenticatedRoute subscription={Sports.NHL} subscription_level={SubscriptionLevels.PRO}>
            <NhlGames />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/nfl/*"
        element={
          <AuthenticatedRoute subscription={Sports.NFL} subscription_level={SubscriptionLevels.PRO}>
            <NflGames />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/mlb/*"
        element={
          <AuthenticatedRoute subscription={Sports.MLB} subscription_level={SubscriptionLevels.PRO}>
            <MlbGames />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/ncaab/*"
        element={
          <AuthenticatedRoute
            subscription={Sports.NCAAB}
            subscription_level={SubscriptionLevels.PRO}
          >
            <NcaabGames />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/ncaaf/*"
        element={
          <AuthenticatedRoute
            subscription={Sports.NCAAF}
            subscription_level={SubscriptionLevels.PRO}
          >
            <NcaafGames />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/ncaab/top-25"
        element={
          <AuthenticatedRoute
            subscription={Sports.NCAAF}
            subscription_level={SubscriptionLevels.PRO}
          >
            <NcaabTop25 />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/ncaab/ranks"
        element={
          <AuthenticatedRoute
            accessLevel={5}
            subscription={Sports.NCAAF}
            subscription_level={SubscriptionLevels.PRO}
          >
            <NcaabRanks />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/welcome"
        element={
          <AuthenticatedRoute>
            <Welcome />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/tutorials"
        element={
          <AuthenticatedRoute
            subscription={Sports.ALL}
            subscription_level={SubscriptionLevels.LITE}
          >
            <Tutorials />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/tutorials/point-spreads-vs-moneylines"
        element={
          <AuthenticatedRoute
            subscription={Sports.ALL}
            subscription_level={SubscriptionLevels.LITE}
          >
            <PointSpreads />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/tutorials/betting-percentages"
        element={
          <AuthenticatedRoute
            subscription={Sports.ALL}
            subscription_level={SubscriptionLevels.LITE}
          >
            <BettingPercentages />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/tutorials/selectivity"
        element={
          <AuthenticatedRoute
            subscription={Sports.ALL}
            subscription_level={SubscriptionLevels.LITE}
          >
            <Selectivity />
          </AuthenticatedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password/:reset_hash" element={<ResetPasswordPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SubscriptionProvider>
          <SettingsProvider>
            <Router />
          </SettingsProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
