import { checkRegistrationStatus, setRegistrationStatus } from 'common/api/admin/registrationOpen';
import { getUsers } from 'common/api/admin/users';
import useAuth from 'common/auth/useAuth';
import SwitchSection from 'components/modules/switchsection';
import Table from 'components/modules/table';
import { User } from 'models/user';
import { ReactElement, useEffect, useState } from 'react';

const TableColumns = [
  { columnHeader: '#', columnId: 'user_id', copy: false },
  { columnHeader: 'Email', columnId: 'user_email', copy: false },
  { columnHeader: 'Username', columnId: 'username', copy: false },
  {
    columnHeader: 'Last Login',
    columnId: 'last_login',
    copy: false,
    date: true,
    prohibitSort: true,
  },
  {
    columnHeader: 'Subscriptions',
    columnId: 'subscriptions',
    copy: false,
    subscription: true,
    prohibitSort: true,
  },
];

export function UsersPanel(): ReactElement {
  const auth = useAuth();

  const [registrationOpen, setRegistrationOpen] = useState(false);

  const [users, setUsers] = useState<Array<User>>([]);
  const [tableLoading, setTableLoading] = useState(false);

  async function loadUsers() {
    setTableLoading(true);
    getUsers(auth.user).then((data) => {
      setUsers(data);
      setTableLoading(false);
    });
  }

  async function updateRegistrationStatus(status: boolean) {
    setRegistrationStatus(status, auth.user).then((newStatus) => {
      setRegistrationOpen(!!newStatus.registration_open);
    });
  }

  useEffect(() => {
    loadUsers();
    checkRegistrationStatus().then((status) => {
      setRegistrationOpen(status.registration_open);
    });
  }, []);

  return (
    <div className="flex items-center flex-col">
      <div className="flex-1 min-w-0 w-full max-w-screen-lg mt-5">
        <h2 className="text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:truncate">
          Users
        </h2>
      </div>
      <SwitchSection
        label="Registration Status"
        description="Change the registration status, allowing new members to register without an invitation."
        enabled={registrationOpen}
        onChange={updateRegistrationStatus}
      />
      <Table
        classNames="w-full max-w-screen-lg"
        columns={TableColumns}
        items={users}
        idProp="user_id"
        showLoader={tableLoading}
      ></Table>
    </div>
  );
}
