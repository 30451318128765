import ResetMask from 'components/features/reset';
import { ReactElement } from 'react';

import AuthLayout from 'components/layouts/auth';

export function ResetPasswordPage(): ReactElement {
  return (
    <AuthLayout>
      <ResetMask />
    </AuthLayout>
  );
}
