import { addGameHighlight, removeGameHighlight } from 'common/api/generic/genericHighlight';
import { Game } from 'models/game';
import { User } from 'models/user';

export async function addNhlGameHighlight(
  game: Game,
  highlight_side: string,
  highlight_over_under: string,
  highlight_play: string,
  highlight_comment: string,
  user?: User
): Promise<Game> {
  return await addGameHighlight(
    'nhl',
    game.id,
    highlight_side,
    highlight_over_under,
    highlight_play,
    highlight_comment,
    user
  );
}

export async function removeNhlGameHighlight(game: Game, user?: User): Promise<Game> {
  return await removeGameHighlight('nhl', game.id, user);
}
