import Switch from 'components/elements/switch';
import { ReactElement, useEffect, useState } from 'react';

interface Props {
  label: string;
  description: string;
  enabled: boolean;
  onChange: (value: boolean) => void;
}

export default function SwitchSection({
  label,
  description,
  enabled,
  onChange,
}: Props): ReactElement {
  return (
    <div className="flex w-full max-w-screen-lg bg-white shadow rounded-lg mt-3 px-4 py-5 items-center justify-between">
      <span className="flex flex-grow flex-col">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        <span className="text-sm text-gray-500">{description}</span>
      </span>
      <Switch enabled={enabled} onClick={onChange} />
    </div>
  );
}
