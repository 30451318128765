import { ReactElement, useEffect, useState } from 'react';

import { DuplicateIcon } from '@heroicons/react/outline';
import ColorBox from 'components/elements/colorbox';
import Loader from 'components/elements/loader';
import { Sports } from 'models/enums';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

interface Column {
  columnHeader: string;
  columnId: string;
  colorStat?: boolean;
  value?: boolean;
  alignRight?: boolean;
  copy?: boolean;
  delete?: boolean;
  subscription?: boolean;
  date?: boolean;
  localDate?: boolean;
  prohibitSort?: boolean;
  status?: boolean;
}

interface Props {
  classNames: string;
  columns: Column[];
  showLoader?: boolean;
  items: any[] | undefined;
  idProp: string;
  onDelete?: (e: any) => void;
}

export default function Table({
  classNames,
  columns,
  items,
  idProp,
  showLoader = false,
  onDelete = () => {
    return null;
  },
}: Props): ReactElement {
  const [sortedEntries, setSortedEntries] = useState<any[]>();
  const [currentSort, setCurrentSort] = useState('');
  const [currentSortAsc, setCurrentSortAsc] = useState(true);

  function sortByColumnId(columnId: string) {
    let sortAsc = currentSortAsc;
    if (!columnId || (currentSort == columnId && !sortAsc)) {
      let unsortedItems = [] as any[];
      if (items) {
        unsortedItems = [...items];
      }
      setSortedEntries([...unsortedItems]);
      setCurrentSort('');
      setCurrentSortAsc(true);
      return;
    }

    sortAsc = true;
    if (currentSort == columnId) {
      sortAsc = false;

      // Hacky fix cause desc doesnt sort properly rn
      const sorted = sortedEntries ? [...sortedEntries].reverse() : [];

      setSortedEntries([...sorted]);
      setCurrentSort(columnId);
      setCurrentSortAsc(sortAsc);
      return;
    }

    const sorted = items ? [...items] : [];
    sorted?.sort((a, b) =>
      // TODO desc currently broken
      a[columnId].value
        ? sortAsc
          ? a[columnId].value < b[columnId].value
            ? -1
            : a[columnId].value > b[columnId].value
            ? 1
            : 0
          : b[columnId].value < a[columnId].value
          ? -1
          : b[columnId].value > a[columnId].value
          ? 1
          : 0
        : sortAsc
        ? a[columnId] < b[columnId]
          ? -1
          : a[columnId] > b[columnId]
          ? 1
          : 0
        : b[columnId] < a[columnId]
        ? -1
        : b[columnId] > a[columnId]
        ? 1
        : 0
    ) as any[];

    setSortedEntries([...sorted]);
    setCurrentSort(columnId);
    setCurrentSortAsc(sortAsc);
  }

  useEffect(() => {
    sortByColumnId(currentSort);
  }, [items]);

  return (
    <div className={`${classNames} mt-8 flex flex-col`}>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.columnId}
                      scope="col"
                      className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ${
                        !column.prohibitSort && 'cursor-pointer'
                      } ${column.status && 'w-36'}`}
                      onClick={() => {
                        if (!column.prohibitSort) {
                          sortByColumnId(column.columnId);
                        }
                      }}
                    >
                      <div
                        className={`flex w-full ${column.alignRight && 'text-right justify-end'}`}
                      >
                        <span className="whitespace-nowrap">{column.columnHeader}</span>
                        {!column.prohibitSort && (
                          <>
                            {!column.prohibitSort &&
                              currentSort == column.columnId &&
                              currentSortAsc && <ChevronUpIcon className="w-3 h-3 ml-1 mt-1" />}
                            {currentSort == column.columnId && !currentSortAsc && (
                              <ChevronDownIcon className="w-3 h-3 ml-1 mt-1" />
                            )}
                            {currentSort != column.columnId && (
                              <div className="flex flex-col -my-2 ml-1">
                                <ChevronUpIcon className="w-3 h-3 mt-2" />
                                <ChevronDownIcon className="w-3 h-3 -mt-1.5" />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {showLoader && (
                  <tr>
                    <td colSpan={100}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {sortedEntries?.map((item) => (
                  <tr key={item[idProp]}>
                    {columns.map((column) => (
                      <td
                        key={column.columnId}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ${
                          column.alignRight ? 'text-right' : ''
                        }`}
                      >
                        {column.copy == true ? (
                          <span
                            className="flex items-center cursor-pointer"
                            onClick={() => {
                              navigator.clipboard.writeText(item[column.columnId]);
                            }}
                          >
                            {item[column.columnId]}
                            <DuplicateIcon
                              className="w-4 h-4 ml-2 cursor-pointer"
                              onClick={() => {
                                navigator.clipboard.writeText(item[column.columnId]);
                              }}
                            ></DuplicateIcon>
                          </span>
                        ) : column.colorStat ? (
                          <div>
                            <ColorBox value={item[column.columnId]} />
                          </div>
                        ) : column.value ? (
                          <div>
                            <ColorBox
                              value={item[column.columnId].value}
                              description={item[column.columnId].description}
                            />
                          </div>
                        ) : column.delete ? (
                          <span
                            className="cursor-pointer text-red-600 bold text-xs float-right pr-4"
                            onClick={() => onDelete(item)}
                          >
                            DELETE
                          </span>
                        ) : column.date ? (
                          <span className="text-xs">
                            {item[column.columnId] &&
                              new Date(item[column.columnId]).toLocaleString(undefined, {
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                timeZoneName: 'short',
                              })}
                          </span>
                        ) : column.status ? (
                          <div className="flex relative items-center text-xs capitalize">
                            <div
                              className={`flex items-center absolute transition-opacity duration-400 ${
                                item[column.columnId] == 'operational' ? 'opacity-100' : 'opacity-0'
                              }`}
                            >
                              <div className="w-1.5 h-1.5 rounded-full bg-green-500 mr-2"></div>
                              <span className="text-green-500">{item[column.columnId]}</span>
                            </div>
                            <div
                              className={`flex items-center absolute transition-opacity duration-400 ${
                                item[column.columnId] == 'running' ||
                                item[column.columnId] == 'processing'
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              }`}
                            >
                              <div className="animate-pulse w-1.5 h-1.5 rounded-full bg-blue-500 mr-2"></div>
                              <span className="text-blue-500">{item[column.columnId]}</span>
                            </div>
                            <div
                              className={`flex items-center absolute transition-opacity duration-400 ${
                                item[column.columnId] == 'unstable' ? 'opacity-100' : 'opacity-0'
                              }`}
                            >
                              <div className="w-1.5 h-1.5 rounded-full bg-orange-400 mr-2"></div>
                              <span className="text-orange-400">{item[column.columnId]}</span>
                            </div>
                            <div
                              className={`flex items-center absolute transition-opacity duration-400 ${
                                item[column.columnId] == 'failing' ? 'opacity-100' : 'opacity-0'
                              }`}
                            >
                              <div className="w-1.5 h-1.5 rounded-full bg-red-500 mr-2"></div>
                              <span className="text-red-500">{item[column.columnId]}</span>
                            </div>
                          </div>
                        ) : column.subscription ? (
                          <div className="flex">
                            {item[column.columnId].find(
                              (sub: any) => sub.sport_id == Sports.ALL
                            ) && (
                              <div
                                className={`mx-1 mt-0.5 text-xs text-white px-1 py-0.5 rounded-sm ${
                                  item[column.columnId][0].subscription_level == 1
                                    ? 'bg-sky-700'
                                    : 'bg-orange-700'
                                }`}
                              >
                                {item[column.columnId][0].subscription_level == 1
                                  ? 'SIC Lite'
                                  : 'SIC PRO'}
                              </div>
                            )}
                          </div>
                        ) : (
                          <span>{item[column.columnId]}</span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
                {!showLoader && items && items.length < 1 && (
                  <tr>
                    <td colSpan={100}>
                      <div className="flex justify-center py-3 text-xs font-medium">
                        <span className="uppercase">No entries found</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
