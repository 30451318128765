import styled from '@emotion/styled';

interface LogoProps {
  light: boolean;
}

export const LogoText = styled.h1<LogoProps>`
  font-size: 4rem;
  font-weight: 900;
  margin: 0;
  width: 100%;
  text-align: center;

  color: ${(props) => (props.light ? 'var(--color-1000)' : 'var(--color-0)')};
`;
