import { KeyboardEventHandler, ReactElement } from 'react';

import { StyledErrorMessage, StyledInput, StyledLabel, TextInputWrapper } from './TextInput.styles';

import { ExclamationCircleIcon } from '@heroicons/react/solid';

interface Props {
  id: string;
  type?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  className?: string;
  autocomplete?: string;
  error?: boolean;
  errormessage?: string;
  handleChange: (event: any) => void;
  handleKeypress?: KeyboardEventHandler;
}

export default function TextInput({
  id,
  type,
  placeholder,
  label,
  value,
  className,
  error = false,
  errormessage,
  handleChange,
  handleKeypress,
}: Props): ReactElement {
  return (
    <TextInputWrapper className={className}>
      <StyledLabel htmlFor={id} error={error}>
        {label}
      </StyledLabel>
      <StyledInput
        id={id}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={handleKeypress}
        error={error}
      />
      {error ? <StyledErrorMessage>{errormessage}</StyledErrorMessage> : null}
    </TextInputWrapper>
  );
}

export function TailwindTextInput({
  id,
  type = 'text',
  placeholder,
  label = '',
  value,
  className,
  autocomplete,
  error = false,
  errormessage,
  handleChange,
  handleKeypress,
}: // TODO Disabled State
Props): ReactElement {
  return (
    <div className={className}>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={`${label !== '' ? 'mt-1' : ''} relative rounded-md shadow-sm`}>
        <input
          type={type}
          name={id}
          id={id}
          value={value}
          className={
            error
              ? 'leading-5 block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md overflow-visible'
              : 'leading-5 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md overflow-visible'
          }
          placeholder={placeholder}
          aria-invalid={error}
          aria-describedby={`${id}-error`}
          autoComplete={autocomplete}
          onChange={handleChange}
          onKeyPress={handleKeypress}
        />
        {error ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-600" id={`${id}-error`}>
          {errormessage}
        </p>
      ) : null}
    </div>
  );
}
