import { DailyPicks } from 'models/game';
import { User } from 'models/user';
import { authorizedApiCall } from '../apiCall';

export async function getDailyPicks(user?: User): Promise<DailyPicks> {
  if (!user) {
    console.info('no user');
    return { mlb: [], nba: [], ncaab: [], ncaaf: [], nfl: [], nhl: [] };
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/daily-picks`, options, user);
  console.info(res);

  return res.data;
}
