import ForgotPasswordMask from 'components/features/forgotpassword';
import { ReactElement } from 'react';

import AuthLayout from 'components/layouts/auth';

export function ForgotPasswordPage(): ReactElement {
  return (
    <AuthLayout>
      <ForgotPasswordMask />
    </AuthLayout>
  );
}
