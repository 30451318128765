import { ReactElement } from 'react';

export default function Welcome(): ReactElement {
  return (
    <div className="h-full pt-12 pb-20 flex flex-col align-center bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <div className="flex justify-center align-center px-4 mb-12">
              <img className="h-20 w-auto" src="/LogoSlate.png" alt="Sports Investing Club Logo" />
            </div>
            <p className="text-base font-semibold text-indigo-600">Sports Investing Club</p>
            <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight">
              Welcome To The Club
            </h1>
            <p className="mt-2 text-base text-gray-500">
              You&apos;re all set to enhance your betting game!
            </p>
            <div className="mt-6 mb-24">
              <a href="/" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                Get Started<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
