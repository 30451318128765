import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import { Link } from 'react-router-dom';

export default function Tutorials(): ReactElement {
  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-md mt-5">
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate leading-normal mb-6">
            SIC Tutorials
          </h1>
          <div className="-mx-4">
            <Link to="/tutorials/point-spreads-vs-moneylines">
              <div className="border border-gray-300 hover:border-gray-400 px-4 py-4 rounded-xl bg-white shadow transition-all opacity-80 hover:opacity-100 mb-3">
                <h2 className="font-medium mb-2">Point Spreads versus Moneylines</h2>
                <p className="text-sm">
                  Learn more about when to focus on point spread, and when to explore moneyline odds
                </p>
              </div>
            </Link>
            <Link to="/tutorials/betting-percentages">
              <div className="border border-gray-300 hover:border-gray-400 px-4 py-4 rounded-xl bg-white shadow transition-all opacity-80 hover:opacity-100 mb-3">
                <h2 className="font-medium mb-2">Focus on Betting Percentage & Money Percentage</h2>
                <p className="text-sm">
                  How does SIC approach betting percentage data, and how do we decide if a game
                  turns into a &quot;play&quot;
                </p>
              </div>
            </Link>
            <Link to="/tutorials/selectivity">
              <div className="border border-gray-300 hover:border-gray-400 px-4 py-4 rounded-xl bg-white shadow transition-all opacity-80 hover:opacity-100 mb-3">
                <h2 className="font-medium mb-2">Selectivity and Sports Betting</h2>
                <p className="text-sm">
                  How successful is SIC, and how do we ensure it will be in the future
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </FullLayout>
  );
}
