import { StripeSession } from 'models/subscription/CheckoutSession';
import { User } from 'models/user';
import { authorizedApiCall } from '../apiCall';

export async function getCheckoutSession(
  subLevel: string,
  subDuration: string,
  user?: User
): Promise<StripeSession> {
  if (!user) {
    return { url: '' };
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(
    `/checkout?level=${subLevel}&duration=${subDuration}`,
    options,
    user
  );

  return res.data;
}

export async function getCustomerPortalSession(user?: User): Promise<StripeSession> {
  if (!user) {
    return { url: '' };
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/customer-portal`, options, user);

  return res.data;
}
