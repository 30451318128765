import { User } from 'models/user';
import { getCurrentGameData, getGameData } from '../../generic/genericDownload';

export async function getMlbGameData(user?: User) {
  getGameData('mlb', user);
}

export async function getCurrentMlbGameData(user?: User) {
  getCurrentGameData('mlb', user);
}
