import { ReactElement } from 'react';

import { AuthWrapper, AuthLogo } from './AuthLayout.styles';

interface Props {
  children: ReactElement | ReactElement[];
}

export default function AuthLayout({ children }: Props): ReactElement {
  return <AuthWrapper>{children}</AuthWrapper>;
}
