import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

interface NavProps {
  open: boolean;
}

interface NavToggleProps {
  open: boolean;
}

interface NavItemProps {
  open?: boolean;
  isCurrent?: boolean;
}

interface ProfilePictureProps {
  url: string;
}

export const NavHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 1.2rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
`;

export const Profile = styled(Link)`
  font-size: 1.6rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-purpler);
`;

export const ProfileImage = styled.div<ProfilePictureProps>`
  height: 3.6rem;
  width: 3.6rem;
  margin-left: -0.2rem;
  background: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4rem;
  border: 0.2rem solid var(--color-1000);
  margin-right: 1.2rem;
`;

export const ProfileName = styled.span`
  min-width: 100%;
  color: var(--color-1000);
`;

export const LogoutButton = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--color-1000);
`;

export const Navigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NavItem = styled(Link)<NavItemProps>`
  width: 100%;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0.4rem;
  box-sizing: border-box;
  margin-bottom: 0.8rem;
  transition: background ease 0.3s;

  background: ${(props) => (props.isCurrent ? 'var(--color-100)' : 'var(--color-200)')};
  color: ${(props) => (props.isCurrent ? 'var(--color-1000)' : 'var(--color-850)')};

  &:hover {
    background: ${(props) => (props.isCurrent ? 'var(--color-100)' : 'var(--color-400)')};
    color: var(--color-1000);
  }
`;

export const NavSubItem = styled(Link)<NavItemProps>`
  width: 100%;
  padding: 0.8rem 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  border-radius: 0.4rem;
  box-sizing: border-box;
  margin-bottom: 0.8rem;
  transition: background ease 0.3s;

  background: ${(props) => (props.isCurrent ? 'var(--color-100)' : 'var(--color-200)')};
  color: ${(props) => (props.isCurrent ? 'var(--color-1000)' : 'var(--color-850)')};

  &:hover {
    background: ${(props) => (props.isCurrent ? 'var(--color-100)' : 'var(--color-400)')};
    color: var(--color-1000);
  }
`;

export const NavItemToggle = styled.div<NavToggleProps>`
  a:not(:first-of-type) {
    display: ${(props) => (props.open ? 'flex' : 'hidden')};
  }
`;

const openNavStyles = css`
  width: 30%;
  max-width: 32rem;
  min-width: 12rem;
`;

export const NavWrapper = styled.div`
  flex-direction: column;
  height: 100%;
  width: auto;
  box-sizing: border-box;
  padding: 2rem 1.8rem;
  background: var(--color-200);
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  width: 30%;
  max-width: 32rem;
  min-width: 12rem;
`;

export const NavFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavVersion = styled.span`
  text-align: center;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: var(--color-800);
`;

export const NavHelpLink = styled.a`
  text-align: center;
  font-size: 1rem;
  margin: 0.4rem 0.4rem;
  text-decoration: underline;
  color: var(--color-800);
`;
