import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import InvitePanel from 'components/features/invitepanel';

export default function Invite(): ReactElement {
  return (
    <FullLayout className="bg-gray-100">
      <InvitePanel />
    </FullLayout>
  );
}
