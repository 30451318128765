import styled from '@emotion/styled';

interface BoxProps {
  background: string;
  text: string;
}

export const StyledColorBox = styled.div<BoxProps>`
  border-radius: 0.4rem;
  font-size: 1.4rem;
  display: inline-block;
  padding: 0.4rem 1.2rem;
  text-align: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.text};
`;

export const StyledBox = styled.div`
  background-color: white;
  display: inline-block;
  border-radius: 0.4rem;
`;
