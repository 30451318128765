import { Status } from 'models/status';
import { User } from 'models/user';
import { authorizedApiCall } from '../apiCall';

export async function getStatus(user?: User): Promise<Array<Status>> {
  if (!user) {
    return [];
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/status`, options, user);

  return res.data;
}
