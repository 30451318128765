import { getCustomerPortalSession } from 'common/api/subscription/session';
import useAuth from 'common/auth/useAuth';
import { ReactElement, useState } from 'react';

export function ComeBackPanel(): ReactElement {
  const auth = useAuth();
  const [loadingNavigation, setLoadingNavigation] = useState(false);

  async function openCustomerPortal() {
    setLoadingNavigation(true);
    const session = await getCustomerPortalSession(auth.user);
    window.location.href = session.url;
  }

  return (
    <div className="w-full max-w-screen-lg h-full flex justify-center md:mx-auto py-6 md:py-24 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:flex-col sm:align-center sm:justify-center">
        <h1 className="text-5xl -mt-20 tracking-tight font-bold text-gray-900 sm:text-center">
          Sports Investing Club
        </h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">Your subscription has expired.</p>

        <div className="flex flex-col items-center justify-center">
          <a
            className="w-40 mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 cursor-pointer"
            onClick={() => openCustomerPortal()}
          >
            Re-join SIC now
          </a>
          <p className="mt-5 text-sm text-gray-500 sm:text-center">
            If you think this is a mistake, please click the link above
            <br /> and check if all invoices are marked as &quot;Paid&quot;.
          </p>
          <p className="mt-3 text-sm">
            Or contact us through{' '}
            <a className="underline" href="mailto:help@sportsinvestingclub.com">
              help@sportsinvestingclub.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
