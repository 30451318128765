import styled from '@emotion/styled';
import Logo from 'components/elements/logo';

export const AuthWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-image: url('https://app.sportsinvestingclub.com/images/background.jpg');
  background-size: cover;
`;

export const AuthLogo = styled(Logo)`
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  max-width: 100%;
  color: rgba(20, 20, 20, 0.4);
`;
