import FilterBadge from 'components/elements/filterbadge';
import { ReactElement, useState, useEffect } from 'react';

interface Props {
  onChange: ([]) => void;
}

const filterOptions = ['Lean', 'Play', 'Strong Play'];

export default function GameFilter({ onChange }: Props): ReactElement {
  const [filters, setFilters] = useState<Array<string>>([]);

  function toggleFilter(option: string) {
    if (filters.includes(option)) {
      setFilters(filters.filter((i) => i != option));
    } else {
      setFilters([...filters, option]);
    }
  }

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  return (
    <div className="mb-5">
      {filterOptions.map((option) => {
        return (
          <FilterBadge
            className="mr-2"
            key={option}
            active={filters.includes(option)}
            label={option}
            onClick={() => {
              toggleFilter(option);
            }}
          />
        );
      })}
    </div>
  );
}
