import { Sports } from 'models/enums';
import { User } from 'models/user';
import { NotFound } from 'pages';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import * as sessionsApi from './sessions';
import * as usersApi from './users';

interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: any;
  login: (login: string, password: string) => void;
  signUp: (invite_hash: string, user_email: string, username: string, password: string) => void;
  logout: (user?: User) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, signal the component that the initial load is over.
  useEffect(() => {
    usersApi
      .getCurrentUser()
      .then((user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
      })
      .catch((_error: Error) => {
        console.log(`login failed ${_error.message}`);
        localStorage.removeItem('user');
      })
      .finally(() => setLoadingInitial(false));
  }, []);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  function login(login: string, password: string) {
    setLoading(true);

    sessionsApi
      .login({ login, password })
      .then((user) => {
        if (user.username) {
          setError(undefined);
          setUser(user);
          localStorage.setItem('user', JSON.stringify(user));
          navigate('/');
        } else {
          setError('no user');
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  function signUp(invite_hash: string, user_email: string, username: string, password: string) {
    setLoading(true);

    usersApi
      .signUp({ invite_hash, user_email, username, password })
      .then((user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        navigate('/');
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout(user?: User) {
    sessionsApi.logout(user).then(() => {
      setUser(undefined);
      localStorage.removeItem('user');
    });
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      signUp,
      logout,
    }),
    [user, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}

export const AuthenticatedRoute = ({
  children,
  accessLevel = 1,
  subscription,
  subscription_level = 1,
}: {
  children: JSX.Element;
  accessLevel?: number;
  subscription?: number;
  subscription_level?: number;
}) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  if (user.subscriptions) {
    const user_sub = user.subscriptions.find(
      (sub) =>
        (sub.sport_id == subscription || sub.sport_id == Sports.ALL) &&
        subscription_level &&
        sub.subscription_level >= subscription_level
    );

    if (!!subscription && !user_sub && user.user_level < 3) return <NotFound />;

    if (!subscription && subscription_level == 2 && user.user_level < 4) return <NotFound />;
  }

  if (user.user_level < accessLevel) return <NotFound />;

  return children;
};
