import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import StatusPanel from 'components/features/statuspanel';

export default function Status(): ReactElement {
  return (
    <FullLayout className="bg-gray-100">
      <StatusPanel />
    </FullLayout>
  );
}
