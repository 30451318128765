import { apiCall, authorizedApiCall } from 'common/api/apiCall';
import { User } from 'models/user';

export async function login(params: { login: string; password: string }): Promise<User> {
  const options = {
    method: 'POST',
  };

  const fetchString = `/session?user=${encodeURIComponent(
    params.login
  )}&password=${encodeURIComponent(params.password)}`;
  const response = await apiCall(fetchString, options);

  return await response.data;
}

export async function logout(user?: User) {
  if (!user) {
    return;
  }
  const options = {
    method: 'DELETE',
  };

  const response = await authorizedApiCall('/session', options, user);

  return await response.data;
}
