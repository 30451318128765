import styled from '@emotion/styled';

interface ColorBarProps {
  flipped: boolean;
}

interface ColorBarPartProps {
  width: number;
  highlight: boolean;
}

export const DistributionBarWrapper = styled.div<ColorBarProps>`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: ${(props) => (props.flipped ? 'column-reverse' : 'column')};
`;

export const DistributionBarHeader = styled.div<ColorBarProps>`
  display: flex;
  justify-content: space-between;

  align-items: ${(props) => (props.flipped ? 'flex-start' : 'flex-end')};
  margin: ${(props) => (props.flipped ? '0' : '0 0 .4rem')};
`;

export const DistributionBarTitle = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const DistributionBarNumber = styled.span`
  font-size: 1.2rem;
`;

export const DistributionColorBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

export const ColorBarPart = styled.div<ColorBarPartProps>`
  width: ${(props) => props.width + '%'};
  height: 1rem;
  background: ${(props) => (props.highlight ? 'var(--color-100)' : 'var(--color-800)')};
  border-radius: 0.2rem 0 0 0.2rem;

  :last-child {
    flex: 1;
    border-radius: 0 0.2rem 0.2rem 0;
  }
`;
