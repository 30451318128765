import { ReactElement, useEffect, useState } from 'react';
import useAuth from 'common/auth/useAuth';
import { User } from 'models/user';

import { TailwindTextInput } from 'components/elements/textinput';
import FormSection from 'components/modules/formsection';

import { updateUserData } from 'common/auth/users';

import { CheckCircleIcon } from '@heroicons/react/outline';

export function UserSettingsForm() {
  const auth = useAuth();
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');

  const [username, setUsername] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [validationErrors, setValidationErrors] = useState<any>({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth.user) {
      setName(auth.user.name ? auth.user.name : '');
      setLastname(auth.user.lastname ? auth.user.lastname : '');
      setUsername(auth.user.username ? auth.user.username : '');
      setUserEmail(auth.user.user_email ? auth.user.user_email : '');
    }
  }, []);

  const saveUserData = async () => {
    if (username && userEmail) {
      setLoading(true);
      const newUser = auth.user as User;
      newUser.name = name;
      newUser.lastname = lastname;
      newUser.username = username;
      newUser.user_email = userEmail;

      const res = await updateUserData(newUser);
      setError(res.error);
      setValidationErrors(await res.data);
      setLoading(false);

      if (!res.error) {
        setSuccess(true);
      }
    } else {
      setError(true);
      setValidationErrors({
        username: !username ? 'Username cannot be empty!' : false,
        email: !userEmail ? 'Email cannot be empty!' : false,
      });
    }
  };

  const handleKeypress = (event: any) => {
    if (event.key == 'Enter' && !error) {
    }
  };

  return (
    <FormSection
      sectionHeader="Your Profile"
      sectionFlavour="Change your profile information here."
    >
      <div className="px-4 py-5 bg-white sm:p-6">
        <div className="grid grid-cols-6 gap-6">
          <TailwindTextInput
            className="col-span-6 sm:col-span-4"
            id="username"
            placeholder="username"
            label="Username"
            value={username}
            error={!!validationErrors.username && error}
            errormessage={validationErrors.username}
            handleKeypress={handleKeypress}
            handleChange={(event) => {
              setUsername(event.target.value);
              setSuccess(false);
              delete validationErrors.username;
            }}
          />
          <TailwindTextInput
            className="col-span-6 sm:col-span-3"
            id="name"
            placeholder="John"
            label="First name"
            value={name}
            error={!!validationErrors.name && error}
            errormessage={validationErrors.name}
            handleKeypress={handleKeypress}
            handleChange={(event) => {
              setName(event.target.value);
              setSuccess(false);
              delete validationErrors.name;
            }}
          />
          <TailwindTextInput
            className="col-span-6 sm:col-span-3"
            id="lastname"
            placeholder="Doe"
            label="Last Name"
            value={lastname}
            error={!!validationErrors.lastname && error}
            errormessage={validationErrors.lastname}
            handleKeypress={handleKeypress}
            handleChange={(event) => {
              setLastname(event.target.value);
              setSuccess(false);
              delete validationErrors.lastname;
            }}
          />
          <TailwindTextInput
            className="col-span-6 sm:col-span-4"
            id="userEmail"
            placeholder="user@email.com"
            label="Email address"
            value={userEmail}
            error={!!validationErrors.email && error}
            errormessage={validationErrors.email}
            handleKeypress={handleKeypress}
            handleChange={(event) => {
              setUserEmail(event.target.value);
              setSuccess(false);
              delete validationErrors.email;
            }}
          />
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right flex items-center justify-end sm:px-6">
        {success && (
          <span className="flex items-center text-xs mr-5">
            <CheckCircleIcon className="w-5 h-5 mr-1 text-green-600" />
            Changes saved successfully!
          </span>
        )}
        <button
          onClick={saveUserData}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={loading}
        >
          Save
        </button>
      </div>
    </FormSection>
  );
}
