import { BASE_URL } from 'App';
import { User } from 'models/user';

export async function apiCall(url: RequestInfo, options: any) {
  const res = {
    data: {} as any,
    status: {},
    error: false,
    loading: true,
  };

  await fetch(`${BASE_URL}${url}`, options)
    .then((response) => {
      res.status = response.status;
      if (!response.ok) {
        res.error = true;
      }
      res.data = response.json();
    })
    .finally(() => {
      res.loading = false;
    });
  return res;
}

export async function authorizedApiCall(url: RequestInfo, options: any, user?: User) {
  if (user) {
    options.headers = new Headers({
      Authorization: 'Basic ' + window.btoa(`${user.user_id}:${user.session}`),
    });
  }

  const res = {
    data: {} as any,
    status: {},
    error: false,
    loading: true,
  };

  await fetch(`${BASE_URL}${url}`, options)
    .then((response) => {
      res.status = response.status;
      if (!response.ok) {
        res.error = true;
      }
      res.data = response.json();
    })
    .finally(() => {
      res.loading = false;
    });
  return res;
}

export function authorizedDownFetch(url: string, saveas: string, options: any, user?: User) {
  if (user) {
    options.headers = new Headers({
      Authorization: 'Basic ' + window.btoa(`${user.user_id}:${user.session}`),
    });
  }

  fetch(`${BASE_URL}${url}`, options)
    .then((result) => {
      if (result.status != 200) {
        throw new Error('Bad server response');
      }
      return result.blob();
    })

    .then((data) => {
      const url = window.URL.createObjectURL(data),
        anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = saveas;
      anchor.click();

      window.URL.revokeObjectURL(url);
      document.removeChild(anchor);
    })

    .catch((error) => {
      console.error(error);
    });
}
