import { getStatus } from 'common/api/admin/status';
import useAuth from 'common/auth/useAuth';
import Table from 'components/modules/table';
import { Status } from 'models/status';
import { ReactElement, useEffect, useState } from 'react';

const TableColumns = [
  { columnHeader: 'Module', columnId: 'description', copy: false },
  { columnHeader: 'Last Update', columnId: 'last_ran', copy: false, date: true },
  {
    columnHeader: 'Status',
    columnId: 'status',
    copy: false,
    prohibitSort: true,
    status: true,
  },
];

export function StatusPanel(): ReactElement {
  const auth = useAuth();

  const [status, setStatus] = useState<Array<Status>>([]);
  const [tableLoading, setTableLoading] = useState(false);

  async function loadUsers() {
    getStatus(auth.user).then((data) => {
      setStatus(data);
      setTableLoading(false);
    });
  }

  useEffect(() => {
    setTableLoading(true);
    loadUsers();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      loadUsers();
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex items-center flex-col">
      <div className="flex justify-between items-center flex-1 min-w-0 w-full max-w-screen-lg mt-5">
        <h2 className="text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:truncate">
          Status Dashboard
        </h2>
        <div className="flex rounded items-center px-3 py-2 bg-white shadow">
          <span className="h-2 w-2 bg-red-500 animate-pulse rounded-full mr-2"> </span>
          <span className="text-sm font-bold text-gray-800">LIVE</span>
        </div>
      </div>
      <Table
        classNames="w-full max-w-screen-lg"
        columns={TableColumns}
        items={status}
        idProp="id"
        showLoader={tableLoading}
      ></Table>
    </div>
  );
}
