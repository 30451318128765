import { ReactElement, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Button from 'components/elements/button';
import { StyledH1 } from 'components/elements/styledtext';

import { ResetWrapper, StyledTextInput } from './ResetMask.styles';
import { resetPassword, verifyResetHash } from 'common/auth/reset';
import { TailwindTextInput } from 'components/elements/textinput';

interface LocationState {
  from: {
    pathname: string;
  };
  login: boolean;
}

export default function ResetMask(): ReactElement {
  const [newpassword, setNewPassword] = useState('');
  const [verifypassword, setVerifyPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [resetHash, setResetHash] = useState('');

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [confirmedHash, setConfirmedHash] = useState(false);

  const { reset_hash } = useParams();

  async function handleReset() {
    setLoading(true);
    const res = await resetPassword(resetHash, newpassword);
    const data = (await res.data) as any;
    if (res.error) {
      setError(true);
      setLoading(false);
    } else if (data?.success) {
      setSuccess(true);
      setError(false);
      setConfirmedHash(false);
    }
  }

  async function verifyHash() {
    if (resetHash == '') {
      setError(true);
    } else {
      const res = await verifyResetHash(resetHash);
      setError(res.error);
      setConfirmedHash(!res.error);
    }
  }

  useEffect(() => {
    setResetHash(reset_hash ?? '');
  }, []);

  useEffect(() => {
    verifyHash();
  }, [resetHash]);

  useEffect(() => {
    if (!newpassword || !verifypassword) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [newpassword, verifypassword]);

  const handleKeypress = (event: any) => {
    if (event.key == 'Enter') {
      handleReset();
    }
  };

  return (
    <div className="h-full flex flex-col bg-white w-full lg:w-auto">
      <div className="mt-12 px-4 sm:px-6 lg:flex-none lg:mt-24 lg:px-20 xl:px-24">
        <img className="h-20 w-auto" src="/LogoSlate.png" alt="Sports Investing Club Logo" />
      </div>
      <div className="flex-1 flex flex-col w-full py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
        <div className="mx-auto w-full lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Reset
              <br /> your password
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Or{' '}
              <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                <span>log in now!</span>
              </Link>
            </p>
          </div>
          <div className="mt-8 mb-12">
            <div className="mt-6">
              <div className="space-y-6">
                {!confirmedHash ? (
                  <>
                    {!confirmedHash && error && <p>This link has expired</p>}
                    {!confirmedHash && success && <p>Password changed successfully.</p>}
                  </>
                ) : (
                  <>
                    <TailwindTextInput
                      id="newpassword"
                      type="password"
                      label="New Password"
                      autocomplete="new-password"
                      handleChange={(event) => {
                        setError(false);
                        setNewPassword(event.target.value);
                      }}
                    />
                    <TailwindTextInput
                      id="password"
                      type="password"
                      label="Repeat Password"
                      autocomplete="new-password"
                      error={
                        newpassword.trim() !== verifypassword.trim() && !!verifypassword && !error
                      }
                      errormessage="Passwords do not match"
                      handleKeypress={handleKeypress}
                      handleChange={(event) => {
                        setError(false);
                        setVerifyPassword(event.target.value);
                      }}
                    />
                    <button
                      name={'reset in'}
                      disabled={!canSubmit || loading}
                      onClick={() => {
                        handleReset();
                      }}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Set new password
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
