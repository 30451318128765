import useAuth from 'common/auth/useAuth';
import useSettings from 'common/provider/SettingsProvider';
import DistributionBar from 'components/elements/distributionbar';
import Switch from 'components/elements/switch';
import { TailwindTextInput } from 'components/elements/textinput';
import { Game } from 'models/game';
import { ReactElement, useEffect, useState } from 'react';
import {
  GameCardHeader,
  GameCardHeaderCol,
  GameCardOddsCard,
  GameCardPitchers,
  GameCardScore,
  GameCardScoreTeam,
  GameCardTeam,
  GameCardTimeData,
} from './GameCard.styles';

interface Props {
  game: Game;
  className?: string;
  spreadSport?: boolean;
  displayAllPercentages: boolean;
  fillNss?: boolean;
  addHighlight?: (
    game: Game,
    highlight_side: string,
    highlight_over_under: string,
    highlight_play: string,
    highlight_comment: string
  ) => void;
  removeHighlight?: (game: Game) => void;
}

export default function GameCard({
  game,
  className,
  spreadSport = true,
  displayAllPercentages = false,
  fillNss = true,
  addHighlight = () => null,
  removeHighlight = () => null,
}: Props): ReactElement {
  const [over, setOver] = useState('');
  const [under, setUnder] = useState('');
  const [cleanOverUnder, setcleanOverUnder] = useState(0);
  const [overMoneyline, setOverMoneyline] = useState<number>();
  const [underMoneyline, setUnderMoneyline] = useState<number>();
  const [displayPercentages, setDisplayPercentages] = useState(true);

  const [homePitcherString, setHomePitcherString] = useState<string>();
  const [visitingPitcherString, setVisitingPitcherString] = useState<string>();

  const [highlighted, setHighlighted] = useState<boolean>(false);
  const [highlightedSide, setHighlightedSide] = useState<string>('visiting');
  const [highlightedOverUnder, setHighlightedOverUnder] = useState<string>('over');
  const [highlightedPlay, setHighlightedPlay] = useState<string>('sides');
  const [highlightedComment, setHighlightedComment] = useState<string>('');

  const [highlightable, setHighlightable] = useState<boolean>(false);

  const auth = useAuth();
  const settings = useSettings();

  function formatDate(date: string): string {
    const cdate = new Date(date);
    const timeString = cdate.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      timeStyle: 'short',
    });

    const dateString = cdate.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      dateStyle: 'short',
    });

    const timezoneString = cdate
      .toLocaleString('en-US', {
        timeZone: 'America/New_York',
        timeZoneName: 'short',
      })
      .split(' ');

    return `${dateString} ${timeString} ${timezoneString[timezoneString.length - 1]}`;
  }

  function saveHighlighted() {
    if (highlighted) {
      addHighlight(
        game,
        highlightedSide,
        highlightedOverUnder,
        highlightedPlay,
        highlightedComment.trim()
      );
    } else {
      removeHighlight(game);
    }
  }

  useEffect(() => {
    if (game.odds[0]?.over) {
      setOver('o' + game.odds[0].over);
      setUnder('u' + game.odds[0].over);
      setcleanOverUnder(game.odds[0].over);
      setOverMoneyline(game.odds[0].over_line);

      let counterML = -220 - game.odds[0].over_line;
      if (counterML > -100) {
        counterML = 200 + counterML;
      }
      setUnderMoneyline(counterML);
    } else if (game.odds[0]?.under) {
      setOver('o' + game.odds[0].under);
      setUnder('u' + game.odds[0].under);
      setcleanOverUnder(game.odds[0].under);
      setUnderMoneyline(game.odds[0].under_line);

      let counterML = -220 - game.odds[0].under_line;
      if (counterML > -100) {
        counterML = 200 + counterML;
      }
      setOverMoneyline(counterML);
    }

    if (game.home_starting_pitcher && game.visiting_starting_pitcher) {
      const home_pitcher = `${game.home_starting_pitcher} ${
        game.home_starting_pitcher_left_handed ? '(L)' : '(R)'
      }`;
      const visisting_pitcher = `${game.visiting_starting_pitcher} ${
        game.visiting_starting_pitcher_left_handed ? '(L)' : '(R)'
      }`;
      setHomePitcherString(home_pitcher);
      setVisitingPitcherString(visisting_pitcher);
    }

    if (
      !game.odds[0]?.visiting_points_bet_percentage &&
      !game.odds[0]?.visiting_moneyline_bet_percentage &&
      !game.odds[0]?.over_bet_percentage
    ) {
      setDisplayPercentages(false);
    }

    setHighlighted(game.highlighted);
    if (game.highlighted) {
      setHighlightedSide(game.highlighted_side);
      setHighlightedOverUnder(game.highlighted_over_under);
      setHighlightedPlay(game.highlighted_play);
      setHighlightedComment(game.highlighted_comment);
    }

    setHighlightable(
      game.odds[0] &&
        game.odds[0].home_odds_points !== null &&
        game.odds[0].visiting_odds_points !== null &&
        (game.odds[0].over || game.odds[0].under) !== null &&
        game.odds[0].home_odds_moneyline !== null &&
        game.odds[0].visiting_odds_moneyline !== null
    );
  }, [game]);

  useEffect(() => {
    if (
      !game.odds[0]?.visiting_points_bet_percentage &&
      !game.odds[0]?.visiting_moneyline_bet_percentage &&
      !game.odds[0]?.over_bet_percentage
    ) {
      setDisplayPercentages(false);
    } else {
      setDisplayPercentages(displayAllPercentages);
    }
  }, [displayAllPercentages]);

  return (
    <div className={`${className} relative shadow mb-5 p-2 pb-3 rounded-md bg-white`}>
      {game.highlighted && (
        <div className="absolute -top-3 left-2.5 text-xs uppercase font-bold bg-white px-1.5 py-1 rounded shadow">
          Recommended Play
        </div>
      )}
      <GameCardHeader
        className={`flex-col md:flex-row overflow-hidden rounded-t-md ${
          game.highlighted ? 'bg-indigo-700' : 'bg-gray-800'
        }`}
      >
        <GameCardHeaderCol className="w-full md:w-auto">
          <GameCardTimeData>
            <span>{formatDate(game.date)}</span>
          </GameCardTimeData>
          <GameCardTeam>
            <span>{game.visiting_name}</span>
          </GameCardTeam>
          <span> VS </span>
          <GameCardTeam>
            <span>{game.home_name}</span>
          </GameCardTeam>
          {visitingPitcherString && homePitcherString ? (
            <GameCardPitchers>
              <span>{`${visitingPitcherString} - ${homePitcherString}`}</span>
            </GameCardPitchers>
          ) : (
            ''
          )}
        </GameCardHeaderCol>
        <GameCardHeaderCol className="flex md:block justify-end w-full md:w-auto">
          {game.highlighted ? (
            <>
              {['both', 'sides'].includes(game.highlighted_play) && (
                <GameCardScore className="mt-2 md:mt-0" complete={true}>
                  <b>Recommended Play</b> on{' '}
                  <GameCardScoreTeam>
                    {game.highlighted_side == 'home'
                      ? game.home_name +
                        ' ' +
                        (spreadSport
                          ? game.odds[0].home_odds_points
                          : game.odds[0].home_odds_moneyline)
                      : game.visiting_name +
                        ' ' +
                        (spreadSport
                          ? game.odds[0].visiting_odds_points
                          : game.odds[0].visiting_odds_moneyline)}
                  </GameCardScoreTeam>
                </GameCardScore>
              )}
              {['both', 'total'].includes(game.highlighted_play) && (
                <GameCardScore className="mt-2 md:mt-0" complete={true}>
                  <b>Recommended Play</b> on{' '}
                  <GameCardScoreTeam>
                    {game.highlighted_over_under == 'over'
                      ? `o${cleanOverUnder}`
                      : `u${cleanOverUnder}`}
                  </GameCardScoreTeam>
                </GameCardScore>
              )}
            </>
          ) : (
            <>
              <GameCardScore className="mt-2 md:mt-0" complete={game.score.score_complete}>
                <b>{game.score.score}</b> on{' '}
                {game.score.score == 'No Play' ? (
                  <span>Sides</span>
                ) : (
                  <GameCardScoreTeam>
                    {game.score.score_home ? game.home_name : game.visiting_name}
                  </GameCardScoreTeam>
                )}
              </GameCardScore>
              <GameCardScore className="mt-2 md:mt-0" complete={game.score.ou_score_complete}>
                <b>{game.score.ou_score}</b> on{' '}
                {game.score.ou_score == 'No Play' ? (
                  <span>Total</span>
                ) : (
                  <GameCardScoreTeam>
                    {game.score.ou_score_under ? `u${cleanOverUnder}` : `o${cleanOverUnder}`}
                  </GameCardScoreTeam>
                )}
              </GameCardScore>
            </>
          )}
        </GameCardHeaderCol>
      </GameCardHeader>

      <div className="flex flex-1 flex-col px-2 pt-2">
        <div className="w-full flex mb-3">
          {game.NSS && (
            <span className="text-white mr-2 text-sm">
              {fillNss ? ('00' + parseInt(game.NSS)).slice(-3) : parseInt(game.NSS)}
            </span>
          )}
          <span className={`flex-1 font-bold text-xs`}>Team</span>
          <span className="w-1/5 font-bold text-xs text-center">Spread</span>
          <span className="w-1/5 font-bold text-xs text-center">Moneyline</span>
          <span className="w-1/5 font-bold text-xs text-center">Total</span>
        </div>

        <div className="w-full flex mb-1 items-center">
          {game.NSS && (
            <span className="text-sm mr-2">
              {fillNss ? ('00' + parseInt(game.NSS)).slice(-3) : parseInt(game.NSS)}
            </span>
          )}
          <span className="flex-1">{game.visiting_name}</span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              spreadSport &&
              !game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play' &&
              !game.highlighted
                ? 'font-bold'
                : ''
            }`}
          >
            <span>
              {game.odds[0]?.visiting_odds_points == 0 ? 'PK' : game.odds[0]?.visiting_odds_points}
            </span>
            <span className="text-xs md:ml-1">{game.odds[0]?.visiting_odds_points_moneyline}</span>
          </span>
          <span
            className={`w-1/5 text-center ${
              !spreadSport &&
              !game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play' &&
              !game.highlighted
                ? 'font-bold'
                : ''
            }`}
          >
            {game.odds[0]?.visiting_odds_moneyline}
          </span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              !game.score.ou_score_under &&
              game.score.ou_score_complete &&
              game.score.ou_score !== 'No Play' &&
              !game.highlighted
                ? 'font-bold'
                : ''
            }`}
          >
            <span>{over}</span>
            <span className="text-xs md:ml-1">{overMoneyline}</span>
          </span>
        </div>

        <div className="w-full flex items-center">
          {game.NSS && (
            <span className="text-sm mr-2">
              {fillNss ? ('00' + (parseInt(game.NSS) + 1)).slice(-3) : parseInt(game.NSS) + 1}
            </span>
          )}
          <span className="flex-1">{game.home_name}</span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              spreadSport &&
              game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play' &&
              !game.highlighted
                ? 'font-bold'
                : ''
            }`}
          >
            <span>
              {game.odds[0]?.home_odds_points == 0 ? 'PK' : game.odds[0]?.home_odds_points}
            </span>
            <span className="text-xs md:ml-1">{game.odds[0]?.home_odds_points_moneyline}</span>
          </span>
          <span
            className={`w-1/5 text-center ${
              !spreadSport &&
              game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play' &&
              !game.highlighted
                ? 'font-bold'
                : ''
            }`}
          >
            {game.odds[0]?.home_odds_moneyline}
          </span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              game.score.ou_score_under &&
              game.score.ou_score_complete &&
              game.score.ou_score !== 'No Play' &&
              !game.highlighted
                ? 'font-bold'
                : ''
            }`}
          >
            <span>{under}</span>
            <span className="text-xs md:ml-1">{underMoneyline}</span>
          </span>
        </div>
      </div>

      {displayPercentages && (
        <div className="flex mt-4 flex-col md:flex-row">
          <GameCardOddsCard column={true} className="mb-3 md:mb-0">
            <DistributionBar
              left={game.odds[0]?.visiting_points_bet_percentage}
              right={game.odds[0]?.home_points_bet_percentage}
              title="Point Spread Bet %"
            />
            <DistributionBar
              left={game.odds[0]?.visiting_point_spread_handle}
              right={game.odds[0]?.home_point_spread_handle}
              title="Point Spread Money %"
              flipped={true}
            />
          </GameCardOddsCard>
          <GameCardOddsCard column={true} className="mb-3 md:mb-0">
            <DistributionBar
              left={game.odds[0]?.visiting_moneyline_bet_percentage}
              right={game.odds[0]?.home_moneyline_bet_percentage}
              title="Moneyline Bet %"
            />
            <DistributionBar
              left={game.odds[0]?.visiting_moneyline_handle}
              right={game.odds[0]?.home_moneyline_handle}
              title="Moneyline Money %"
              flipped={true}
            />
          </GameCardOddsCard>
          <GameCardOddsCard column={true}>
            <DistributionBar
              side={false}
              left={game.odds[0]?.over_bet_percentage}
              right={game.odds[0]?.under_bet_percentage}
              title="O/U Bet %"
            />
            <DistributionBar
              side={false}
              left={game.odds[0]?.over_handle}
              right={game.odds[0]?.under_handle}
              title="O/U Money %"
              flipped={true}
            />
          </GameCardOddsCard>
        </div>
      )}
      <div className="w-full text-xxs px-2 pt-3 flex justify-between">
        <span>{game.odds[0] && formatDate(game.odds[0]?.data_time)}</span>{' '}
        <span
          className="font-bold uppercase cursor-pointer"
          onClick={() => {
            setDisplayPercentages(!displayPercentages);
          }}
        >
          {displayPercentages ? 'Hide Bet Percentage' : 'Show Bet Percentage'}
        </span>
      </div>
      {auth.user && auth.user.user_level >= 5 && settings.editorMode && highlightable ? (
        <>
          <div className="mt-3 mb-2 px-2">
            <span className="text-sm font-medium">Highlight</span>
          </div>
          <div className={`w-full flex px-2 items-center mb-3`}>
            <div className="flex items-center">
              <Switch enabled={highlighted} onClick={() => setHighlighted(!highlighted)} />
            </div>
            <div className="col-span-2">
              <select
                id="highlight-play"
                name="highlight-play"
                className="block w-full h-8 pl-2 pr-10 py-2 mx-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue="Sides"
                onChange={(v) => {
                  setHighlightedPlay(v.target.value);
                }}
                value={highlightedPlay}
              >
                <option value={'sides'}>Sides</option>
                <option value={'total'}>Total</option>
                <option value={'both'}>Sides + Total</option>
              </select>
            </div>
            {highlightedPlay == 'both' || highlightedPlay == 'sides' ? (
              <div className="col-span-2 ml-3">
                <select
                  id="highlight-side"
                  name="highlight-side"
                  className="block w-full h-8 pl-2 pr-10 py-2 mx-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  defaultValue="visiting"
                  onChange={(v) => {
                    setHighlightedSide(v.target.value);
                  }}
                  value={highlightedSide}
                >
                  <option value={'visiting'}>Visiting</option>
                  <option value={'home'}>Home</option>
                </select>
              </div>
            ) : null}
            {highlightedPlay == 'both' || highlightedPlay == 'total' ? (
              <div className="col-span-2 ml-3">
                <select
                  id="highlight-over-under"
                  name="highlight-over-under"
                  className="block w-full h-8 pl-2 pr-10 py-2 mx-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  defaultValue="over"
                  onChange={(v) => {
                    setHighlightedOverUnder(v.target.value);
                  }}
                  value={highlightedOverUnder}
                >
                  <option value={'over'}>Over</option>
                  <option value={'under'}>Under</option>
                </select>
              </div>
            ) : null}
          </div>
          <div className={`w-full flex px-2 items-center ${game.highlighted ? 'mb-3' : ''}`}>
            <TailwindTextInput
              className="col-span-6 sm:col-span-4 text-base flex-1"
              id="highlight-comment"
              placeholder="Highlight Comment"
              value={highlightedComment}
              error={false}
              errormessage={'false'}
              handleKeypress={() => null}
              handleChange={(event) => {
                setHighlightedComment(event.target.value);
              }}
            />
            <button
              onClick={() => saveHighlighted()}
              className="inline-flex justify-center ml-3 pt-1.5 px-4 h-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </>
      ) : null}
      {game.highlighted && game.highlighted_comment ? (
        <div className="w-full overflow-hidden -mb-3 -mx-2 pl-4 pt-2 pb-2.5 mt-2 rounded-b-md bg-gray-800 box-content">
          <div className="text-white">
            <span className="text-sm font-medium">{game.highlighted_comment}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
