import { apiCall, authorizedApiCall } from 'common/api/apiCall';
import { ApiResponse } from 'models/apiResponse';
import { User } from 'models/user';

export async function getCurrentUser(): Promise<User> {
  const options = {
    method: 'GET',
  };

  const storageUser = localStorage.getItem('user') || '';
  const user = JSON.parse(storageUser);

  const fetchString = `/session`;
  const response = await authorizedApiCall(fetchString, options, user);

  if (response.error) {
    throw Error;
  }

  return response.data;
}

export async function signUp(params: {
  invite_hash: string;
  username: string;
  user_email: string;
  password: string;
}): Promise<User> {
  const options = {
    method: 'POST',
  };

  const fetchString = `/user?invite_hash=${encodeURIComponent(
    params.invite_hash
  )}&username=${encodeURIComponent(params.username)}&email=${encodeURIComponent(
    params.user_email
  )}&password=${encodeURIComponent(params.password)}`;
  const response = await apiCall(fetchString, options);

  if (response.error) {
    throw Error;
  }

  return response.data;
}

export async function updateUserData(updatedUser: User): Promise<ApiResponse> {
  const options = {
    method: 'PUT',
  };

  const storageUser = localStorage.getItem('user') || '';
  const user = JSON.parse(storageUser);

  let fetchString = `/user?username=${encodeURIComponent(
    updatedUser.username
  )}&user_email=${encodeURIComponent(updatedUser.user_email)}&user_id=${user.user_id}`;

  if (updatedUser.lastname) {
    fetchString += `&lastname=${encodeURIComponent(updatedUser.lastname)}`;
  }
  if (updatedUser.name) {
    fetchString += `&name=${encodeURIComponent(updatedUser.name)}`;
  }

  const response = await authorizedApiCall(fetchString, options, user);

  return response;
}

export async function updateUserPassword(
  oldPassword: string,
  newPassword: string
): Promise<ApiResponse> {
  const options = {
    method: 'PUT',
  };

  const storageUser = localStorage.getItem('user') || '';
  const user = JSON.parse(storageUser);

  const fetchString = `/user?old_password=${encodeURIComponent(
    oldPassword
  )}&new_password=${encodeURIComponent(newPassword)}&user_id=${user.user_id}`;
  const response = await authorizedApiCall(fetchString, options, user);

  return response;
}
