import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import ProfilePanel from 'components/features/profilepanel';

export default function Profile(): ReactElement {
  return (
    <FullLayout className="bg-gray-100">
      <ProfilePanel />
    </FullLayout>
  );
}
