import { ReactElement, useEffect, useState } from 'react';
import useAuth from 'common/auth/useAuth';

import { getRankDeltaList } from 'common/api/sports/ncaab/rankDelta';
import { DeltaList, DeltaListData } from 'models/deltalist';

import Table from 'components/modules/table';

const TableColumns = [
  { columnHeader: 'Team', columnId: 'team_name' },
  { columnHeader: 'AP Rank', columnId: 'ap_rank' },
  { columnHeader: 'BT Rank', columnId: 'bt_rank' },
  { columnHeader: 'KP Rank', columnId: 'kp_rank' },
  { columnHeader: 'Average', columnId: 'average_rank' },
  { columnHeader: 'Delta', columnId: 'rank_delta', colorStat: true, alignRight: true },
];

export default function DeltaTable(): ReactElement {
  const auth = useAuth();

  const [data, setData] = useState<Array<DeltaListData>>();
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getDelta();
  }, []);

  async function getDelta() {
    if (auth.user) {
      setTableLoading(true);
      const rdl = await getRankDeltaList(auth.user);

      rdl.data.then((data: DeltaList) => {
        setData(data.delta);
        setTableLoading(false);
      });
    }
  }
  JSON.stringify(data);

  return (
    <>
      <Table
        classNames="w-full max-w-screen-lg"
        columns={TableColumns}
        items={data}
        idProp="user_email"
        showLoader={tableLoading}
      ></Table>
    </>
  );
}
