import { User } from 'models/user';
import { authorizedApiCall } from '../../apiCall';

export async function getRankDeltaList(user: User) {
  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/ncaab/rdelta`, options, user);

  return res;
}

export async function getTop25(user: User) {
  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/ncaab/top25`, options, user);

  return res;
}
