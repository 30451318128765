import { User } from 'models/user';
import { apiCall, authorizedApiCall } from '../apiCall';

export async function checkRegistrationStatus(): Promise<any> {
  const options = {
    method: 'GET',
  };

  const response = await apiCall('/registration-status', options);

  if (response.error) {
    throw Error;
  }

  return await response.data;
}

export async function setRegistrationStatus(status: boolean, user?: User): Promise<any> {
  if (!user) {
    throw Error;
  }

  const options = {
    method: 'PUT',
  };

  const res = await authorizedApiCall(`/registration-status?status=${status}`, options, user);

  if (res.error) {
    throw Error;
  }

  return res.data;
}
