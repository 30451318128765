import styled from '@emotion/styled';

export const LayoutWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const LayoutContent = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;
