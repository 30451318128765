import { User } from 'models/user';
import { getCurrentGameData, getGameData } from '../../generic/genericDownload';

export async function getNcaabGameData(user?: User) {
  getGameData('ncaab', user);
}

export async function getCurrentNcaabGameData(user?: User) {
  getCurrentGameData('ncaab', user);
}
