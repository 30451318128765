import { Game } from 'models/game';
import { ReactElement, useState } from 'react';
import PickCard from '../pickcard';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Loader from 'components/elements/loader';

interface Props {
  games?: Game[];
  spreadSport?: boolean;
  showLoader?: boolean;
}

export function GameCarousel({ games, spreadSport = true, showLoader }: Props): ReactElement {
  const [activeGame, setActiveGame] = useState(0);

  return (
    <div className="relative flex flex-col justify-between">
      {games?.map((game, index) => {
        return (
          <PickCard
            spreadSport={spreadSport}
            className={`${activeGame != index ? 'hidden' : ''}`}
            key={game.home_id + '-' + game.visiting_id + '-' + game.date}
            game={game}
          />
        );
      })}
      {!games?.length && !showLoader && (
        <div className="flex w-full items-center justify-center">
          <h3 className="mb-24 w-full bg-slate-800 py-4 pl-4 text-white text-sm">No Picks Today</h3>
        </div>
      )}
      {showLoader && (
        <div className="flex w-full items-center justify-center">
          <div className="mb-24 w-full bg-slate-800 py-4 pl-4">
            <Loader theme="white" large={false} />
          </div>
        </div>
      )}
      <div className="flex right-0 -top-8 absolute mb-4 justify-end items-center mr-4">
        {games && games.length > 1 && (
          <>
            <ChevronLeftIcon
              className="h-4 w-4 mr-1 text-black cursor-pointer"
              onClick={() =>
                activeGame == 0 ? setActiveGame(games.length - 1) : setActiveGame(activeGame - 1)
              }
            />
            <div className="flex justify-center">
              {games?.map((game, index) => {
                return (
                  <div
                    key={game.home_id + ' ' + game.visiting_id}
                    className={`w-1.5 h-1.5 mx-1 rounded-full cursor-pointer ${
                      activeGame == index ? 'bg-black' : 'bg-gray-500'
                    }`}
                    onClick={() => setActiveGame(index)}
                  ></div>
                );
              })}
            </div>
            <ChevronRightIcon
              className="h-4 w-4 ml-1 text-black cursor-pointer"
              onClick={() =>
                activeGame == games.length - 1 ? setActiveGame(0) : setActiveGame(activeGame + 1)
              }
            />
          </>
        )}
      </div>
    </div>
  );
}
