import { addGameHighlight, removeGameHighlight } from 'common/api/generic/genericHighlight';
import { Game } from 'models/game';
import { User } from 'models/user';

export async function addNflGameHighlight(
  game: Game,
  highlight_side: string,
  highlight_over_under: string,
  highlight_play: string,
  highlight_comment: string,
  user?: User
): Promise<Game> {
  return await addGameHighlight(
    'nfl',
    game.id,
    highlight_side,
    highlight_over_under,
    highlight_play,
    highlight_comment,
    user
  );
}

export async function removeNflGameHighlight(game: Game, user?: User): Promise<Game> {
  return await removeGameHighlight('nfl', game.id, user);
}
