import useAuth from 'common/auth/useAuth';
import { Sports } from 'models/enums';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

interface SubscriptionContextType {
  ALL: { active: boolean; level: number };
  MLB: { active: boolean; level: number };
  NBA: { active: boolean; level: number };
  NCAAB: { active: boolean; level: number };
  NCAAF: { active: boolean; level: number };
  NFL: { active: boolean; level: number };
  NHL: { active: boolean; level: number };
}

const SubscriptionContext = createContext<SubscriptionContextType>({} as SubscriptionContextType);

export function SubscriptionProvider({ children }: { children: ReactNode }): JSX.Element {
  const auth = useAuth();
  const [ALL, setALL] = useState({ active: false, level: 0 });
  const [MLB, setMLB] = useState({ active: false, level: 0 });
  const [NBA, setNBA] = useState({ active: false, level: 0 });
  const [NCAAB, setNCAAB] = useState({ active: false, level: 0 });
  const [NCAAF, setNCAAF] = useState({ active: false, level: 0 });
  const [NFL, setNFL] = useState({ active: false, level: 0 });
  const [NHL, setNHL] = useState({ active: false, level: 0 });

  function setSubscriptions() {
    setALL({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.ALL),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.ALL)?.subscription_level || 0,
    });

    setMLB({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.MLB),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.MLB)?.subscription_level || 0,
    });

    setNBA({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NBA),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NBA)?.subscription_level || 0,
    });

    setNCAAB({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NCAAB),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NCAAB)?.subscription_level ||
        0,
    });

    setNCAAF({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NCAAF),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NCAAF)?.subscription_level ||
        0,
    });

    setNFL({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NFL),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NFL)?.subscription_level || 0,
    });

    setNHL({
      active: !!auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NHL),
      level:
        auth.user?.subscriptions.find((sub) => sub.sport_id == Sports.NHL)?.subscription_level || 0,
    });
  }

  useEffect(() => {
    setSubscriptions();
  }, []);

  useEffect(() => {
    setSubscriptions();
  }, [auth]);

  const memoedValue = useMemo(
    () => ({
      ALL: ALL,
      MLB: MLB,
      NBA: NBA,
      NCAAB: NCAAB,
      NCAAF: NCAAF,
      NFL: NFL,
      NHL: NHL,
    }),
    [ALL, MLB, NBA, NCAAB, NCAAF, NFL, NHL]
  );

  return (
    <SubscriptionContext.Provider value={memoedValue}>{children}</SubscriptionContext.Provider>
  );
}

export default function useSub() {
  return useContext(SubscriptionContext);
}
