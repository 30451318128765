import { ReactElement } from 'react';

interface Props {
  sectionHeader: string;
  sectionFlavour: string;
  children?: ReactElement | ReactElement[];
}

export default function FormSection({
  sectionHeader,
  sectionFlavour,
  children,
}: Props): ReactElement {
  return (
    <div className="mt-10 sm:mt-0 max-w-screen-lg w-full">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{sectionHeader}</h3>
            <p className="mt-1 text-sm text-gray-600">{sectionFlavour}</p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div>
            <div className="shadow overflow-hidden sm:rounded-md">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
