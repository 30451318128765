import { ReactElement, useState, useEffect, useMemo } from 'react';
import useAuth from 'common/auth/useAuth';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TailwindTextInput } from 'components/elements/textinput';
import { checkRegistrationStatus } from 'common/api/admin/registrationOpen';

import { ExclamationCircleIcon } from '@heroicons/react/outline';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function RegisterMask(): ReactElement {
  const auth = useAuth();
  const query = useQuery();
  const navigate = useNavigate();

  const [registrationOpen, setRegistrationOpen] = useState(true);

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [verifypassword, setVerifyPassword] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  const [invited, setInvited] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const user_email = query.get('user_email');
  const invite_hash = query.get('invite_hash');

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }

    setEmail(user_email ?? '');
    setCode(invite_hash ?? '');

    if (user_email) {
      setInvited(true);
    }

    checkRegistrationStatus().then((isOpen) => {
      setRegistrationOpen(isOpen.registration_open || !!invite_hash);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setError(false);
    setLoading(false);

    if (!username.trim() || !password.trim() || password.trim() != verifypassword.trim()) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [code, email, username, password, verifypassword]);

  async function handleRegister() {
    setLoading(true);
    setError(false);
    auth.signUp(code.trim(), email.trim(), username.trim(), password.trim());

    setError(!!auth.error);
  }

  const handleKeypress = (event: any) => {
    if (canSubmit && event.key == 'Enter') {
      handleRegister();
    }
  };

  return (
    <>
      <div className="h-full flex flex-col bg-white w-full lg:w-auto">
        <div className="mt-12 px-4 sm:px-6 lg:flex-none lg:mt-24 lg:px-20 xl:px-24">
          <img className="h-20 w-auto" src="/LogoSlate.png" alt="Sports Investing Club Logo" />
        </div>
        <div className="flex-1 flex flex-col w-full py-12 px-4 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Register
                <br /> your account
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{' '}
                <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                  <span>log into an existing account!</span>
                </Link>
              </p>
            </div>

            <div className="mt-8 mb-12">
              <div className="mt-6">
                {!registrationOpen && (
                  <div className="bg-red-500 rounded mb-4 flex items-center py-3 px-3 text-white">
                    <ExclamationCircleIcon className="h-5 w-5 mr-3" />
                    <span className="text-sm">Registrations are currently closed!</span>
                  </div>
                )}
                <div className="space-y-6">
                  {!invited && (
                    <TailwindTextInput
                      id="email"
                      label="Email"
                      value={email}
                      handleChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  )}

                  <TailwindTextInput
                    id="username"
                    label="Username"
                    value={username}
                    handleChange={(event) => {
                      setUsername(event.target.value);
                    }}
                  />

                  <TailwindTextInput
                    id="password"
                    type="password"
                    label="Password"
                    autocomplete="new-password"
                    value={password}
                    handleChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />

                  <TailwindTextInput
                    id="repeatpassword"
                    type="password"
                    label="Repeat Password"
                    autocomplete="new-password"
                    value={verifypassword}
                    error={password.trim() !== verifypassword.trim() && !!verifypassword}
                    errormessage="Passwords do not match"
                    handleKeypress={handleKeypress}
                    handleChange={(event) => {
                      setVerifyPassword(event.target.value);
                    }}
                  />

                  <div>
                    <button
                      name={'log in'}
                      disabled={!canSubmit || loading || !registrationOpen}
                      onClick={() => {
                        handleRegister();
                      }}
                      className={`${
                        !registrationOpen
                          ? 'bg-indigo-300 hover:bg-indigo-300'
                          : 'bg-indigo-600 hover:bg-indigo-700'
                      } w-full mt-10 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                      Sign up now
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-xs text-center font-medium underline text-slate-700">
              <div className="flex justify-center mb-2 mt-2">
                <a
                  target="_blank"
                  href="https://sportsinvestingclub.com/terms-of-service"
                  rel="noreferrer"
                  className="mr-1"
                >
                  Terms of Service
                </a>
                <a
                  target="_blank"
                  href="https://sportsinvestingclub.com/privacy"
                  rel="noreferrer"
                  className="ml-1"
                >
                  Privacy Policy
                </a>
              </div>
              <a href="mailto:help@sportsinvestingclub.com">Need help?</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
