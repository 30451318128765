import { ReactElement, useEffect, useState } from 'react';
import { css, cx } from '@emotion/css';

import { getNhlGames } from 'common/api/sports/nhl/games';
import { getCurrentNhlGameData, getNhlGameData } from 'common/api/sports/nhl/gameData';

import FullLayout from 'components/layouts/full';
import GamesPanel from 'components/features/gamespanel';
import useAuth from 'common/auth/useAuth';
import Button from 'components/elements/button';
import { DownloadIcon } from '@heroicons/react/solid';
import { Game } from 'models/game';
import { addNhlGameHighlight, removeNhlGameHighlight } from 'common/api/sports/nhl/highlight';

export default function NhlGames(): ReactElement {
  const [games, setGames] = useState([] as any);
  const [gamesLoading, setGamesLoading] = useState(false);

  const auth = useAuth();

  async function loadGames() {
    setGamesLoading(true);
    getNhlGames(auth.user).then((data) => {
      setGames(data);
      setGamesLoading(false);
    });
  }

  async function hightlightGame(
    game: Game,
    side: string,
    overUnder: string,
    play: string,
    comment: string
  ) {
    const new_game = await addNhlGameHighlight(game, side, overUnder, play, comment, auth.user);
    const index = games.findIndex((element: Game) => element.id == new_game.id);
    const copy = [...games];
    const changedGame = copy[index];
    changedGame.highlighted = new_game.highlighted;
    changedGame.highlighted_side = new_game.highlighted_side;
    changedGame.highlighted_over_under = new_game.highlighted_over_under;
    changedGame.highlighted_play = new_game.highlighted_play;
    changedGame.highlighted_comment = new_game.highlighted_comment;
    copy[index] = changedGame;
    setGames(copy);
  }

  async function removeHighlight(game: Game) {
    const new_game = await removeNhlGameHighlight(game, auth.user);
    const index = games.findIndex((element: Game) => element.id == new_game.id);
    const copy = [...games];
    const changedGame = copy[index];
    changedGame.highlighted = new_game.highlighted;
    changedGame.highlighted_side = new_game.highlighted_side;
    changedGame.highlighted_over_under = new_game.highlighted_over_under;
    changedGame.highlighted_play = new_game.highlighted_play;
    changedGame.highlighted_comment = new_game.highlighted_comment;
    copy[index] = changedGame;
    setGames(copy);
  }

  useEffect(() => {
    loadGames();
  }, []);

  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-md mt-5">
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              max-width: 75rem;
              margin-bottom: 2rem;
            `}
          >
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate leading-normal">
              NHL Games
            </h2>
            {games.length > 1 && (
              <div
                className="flex items-center pl-2 pr-3 py-1.5 border-2 border-black rounded-full text-xs font-bold cursor-pointer"
                onClick={() => getCurrentNhlGameData(auth.user)}
              >
                <DownloadIcon className="flex-shrink-0 h-4 w-4" aria-hidden="true" />
                CSV
              </div>
            )}
          </div>
          <GamesPanel
            fillNss={false}
            spreadSport={false}
            games={games}
            showLoader={gamesLoading}
            addHighlight={hightlightGame}
            removeHighlight={removeHighlight}
          />
          <div
            className={css`
              margin-top: 4.8rem;
              display: flex;
              justify-content: flex-end;
              max-width: 75rem;
            `}
          >
            {auth.user?.user_level && auth.user?.user_level >= 5 && (
              <Button name="Download Game Data" onClick={() => getNhlGameData(auth.user)} />
            )}
          </div>
        </div>
      </div>
    </FullLayout>
  );
}
