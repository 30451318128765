import styled from '@emotion/styled';

interface GameScoreProps {
  complete: boolean;
}

export const PickCardHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  margin: -0.8rem -0.8rem 0.8rem;
  padding: 1.6rem;
`;

export const PickCardHeaderCol = styled.div`
  flex: 1;

  :last-child {
    flex: none;
  }
`;

export const PickCardTimeData = styled.div`
  font-size: 1.4rem;
  width: 100%;
`;

export const PickCardTeam = styled.span`
  font-size: 2.2rem;
  margin-right: 0.4rem;
  font-weight: bold;

  :last-of-type {
    margin-left: 0.4rem;
  }
`;

export const PickCardPitchers = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
`;

export const PickCardScore = styled.div<GameScoreProps>`
  text-align: right;
  padding: 0 0.8rem;
  ${(props) => (props.complete ? '' : 'display: none;')}
`;

export const PickCardScoreTeam = styled.span`
  font-size: 1.2;
  font-weight: bold;
`;

export const PickCardGameData = styled.div`
  display: flex;
  flex-direction: row;

  :last-child {
    margin-top: 0.8rem;
  }
`;

interface GameOddsCardProps {
  column?: boolean;
}

export const PickCardOddsCard = styled.div<GameOddsCardProps>`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 0 0.8rem;
  ${(props) => (props.column ? 'flex-direction: column;' : '')}
  flex-wrap: wrap;
`;

export const PickCardOddsCardHeader = styled.div`
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
`;

export const OddsField = styled.span`
  width: 50%;

  &:nth-of-type(even) {
    text-align: right;
  }
`;

export const NoDataNote = styled.span`
  text-align: center;
  width: 100%;
`;

export const PickCardNote = styled.span`
  width: 100%;
  padding: 0.8rem 0 0 0.8rem;
  font-size: 1rem;
`;
