import { User } from 'models/user';
import { authorizedDownFetch } from '../apiCall';

export async function getGameData(sport: string, user?: User) {
  if (!user) {
    return [];
  }

  const options = {
    method: 'GET',
  };

  authorizedDownFetch(`/${sport}/game-data`, `${sport}_games.csv`, options, user);
}

export async function getCurrentGameData(sport: string, user?: User) {
  if (!user) {
    return [];
  }

  const options = {
    method: 'GET',
  };

  const today = new Date();
  const todayString = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(
    -2
  )}-${today.getDate()}_${('0' + today.getHours()).slice(-2)}-${today.getMinutes()}-${(
    '0' + today.getSeconds()
  ).slice(-2)}`;

  authorizedDownFetch(
    `/${sport}/today-game-data`,
    `${todayString}_${sport}_games.csv`,
    options,
    user
  );
}
