interface Props {
  active: boolean;
  label: string;
  onClick: () => void;
  className?: string;
}

export default function FilterBadge({ active, label, onClick, className }: Props) {
  return (
    <>
      {!active && (
        <span
          className={`${className} cursor-pointer inline-flex items-center rounded-full bg-indigo-100 pl-2.5 pr-2 py-0.5 text-sm font-medium text-indigo-400 border-2 border-indigo-100 hover:border-indigo-200 hover:text-indigo-500`}
          onClick={() => onClick()}
        >
          <span className="select-none">{label}</span>
          <svg className="ml-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
            <circle cx={4} cy={4} r={3} />
          </svg>
        </span>
      )}

      {active && (
        <span
          className={`${className} inline-flex cursor-pointer items-center rounded-full bg-indigo-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-indigo-600 border-2 border-indigo-500`}
          onClick={() => onClick()}
        >
          <span className="select-none">{label}</span>
          <button
            type="button"
            className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-700 focus:bg-indigo-500 focus:text-white focus:outline-none"
          >
            <span className="sr-only pointer-events-none">{label}</span>
            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
      )}
    </>
  );
}
