import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import useAuth from 'common/auth/useAuth';
import PricingPanel from 'components/features/pricingpanel';
import DailyPicks from 'components/features/pickspanel';
import useSub from 'common/provider/SubscriptionProvider';
import ComeBackPanel from 'components/features/comebackpanel';

export default function Dashboard(): ReactElement {
  const auth = useAuth();
  const sub = useSub();

  return (
    <FullLayout>
      <div className="flex items-center flex-col h-full">
        {((auth.user?.subscriptions && auth.user?.subscriptions.length > 0) ||
          (auth.user?.user_level && auth.user?.user_level > 1)) && (
          <div className="flex-1 min-w-0 max-w-screen-2.5xl w-full mt-4 md:mt-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate 3xl:mt-4 mb-5 leading-normal hidden md:block">
              Sports Investing Club
            </h2>
            <DailyPicks />
          </div>
        )}

        {!sub.ALL.active &&
          auth.user?.customer_id &&
          auth.user?.user_level &&
          !auth.user?.free_trial_eligible &&
          auth.user?.user_level == 1 && <ComeBackPanel />}

        {!sub.ALL.active &&
          (!auth.user?.customer_id || auth.user?.free_trial_eligible) &&
          auth.user?.user_level &&
          auth.user?.user_level == 1 && <PricingPanel />}
      </div>
    </FullLayout>
  );
}
