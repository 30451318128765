import { ReactElement, useState, useEffect } from 'react';

import { Game } from 'models/game';

import { GamesPanelList, GamesPanelWrapper } from './GamesPanel.styles';
import GameCard from 'components/modules/gamecard';
import Loader from 'components/elements/loader';
import GameFilter from 'components/modules/gamefilter';

interface Props {
  games: Game[];
  spreadSport?: boolean;
  showLoader?: boolean;
  fillNss?: boolean;
  addHighlight: (
    game: Game,
    highlight_side: string,
    highlight_over_under: string,
    highlight_play: string,
    highlight_comment: string
  ) => void;
  removeHighlight: (game: Game) => void;
}

export default function GamesPanel({
  games,
  spreadSport = true,
  showLoader = false,
  fillNss = true,
  addHighlight,
  removeHighlight,
}: Props): ReactElement {
  const [filteredGames, setFilteredGames] = useState<Array<Game>>([]);
  const [displayPercentages, setDisplayPercentages] = useState(true);
  const [filters, setFilters] = useState<Array<string>>([]);

  function filterGames(filters: Array<string>) {
    if (filters.length > 0) {
      setFilters(filters);
      setFilteredGames(
        games.filter((game) => {
          return (
            game.highlighted ||
            (game.score.score_complete && filters.includes(game.score.score)) ||
            (game.score.ou_score_complete && filters.includes(game.score.ou_score))
          );
        })
      );
    } else {
      setFilters([]);
      setFilteredGames(games);
    }
  }

  useEffect(() => {
    filterGames(filters);
  }, [games]);

  return (
    <GamesPanelWrapper>
      {!showLoader && games.length > 0 && (
        <div className="flex justify-between items-center flex-col md:flex-row">
          <GameFilter onChange={filterGames} />
          <span
            className="text-xs uppercase font-bold text-right -mt-2 mb-3 md:mb-5 md:mt-0 cursor-pointer select-none"
            onClick={() => setDisplayPercentages(!displayPercentages)}
          >
            {displayPercentages ? 'hide bet percentages' : 'show bet percentages'}
          </span>
        </div>
      )}
      <GamesPanelList>
        {!showLoader && games.length < 1 && (
          <div className="flex justify-center mt-8 mb-8">
            <p className="uppercase text-sm font-medium">No Games Available</p>
          </div>
        )}
        {!showLoader && filteredGames.length < 1 && filteredGames.length != games.length && (
          <div className="flex justify-center mt-8 mb-8">
            <p className="uppercase text-sm font-medium">No Games Found</p>
          </div>
        )}
        {showLoader && <Loader large={true} />}
        {filteredGames.map((game) => (
          <GameCard
            fillNss={fillNss}
            displayAllPercentages={displayPercentages}
            spreadSport={spreadSport}
            key={game.home_id + '-' + game.visiting_id + '-' + game.date + '-' + game.highlighted}
            addHighlight={addHighlight}
            removeHighlight={removeHighlight}
            game={game}
          />
        ))}
      </GamesPanelList>
    </GamesPanelWrapper>
  );
}
