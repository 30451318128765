import { ReactElement } from 'react';
import {
  ColorBarPart,
  DistributionBarHeader,
  DistributionBarNumber,
  DistributionBarTitle,
  DistributionBarWrapper,
  DistributionColorBar,
} from './DistributionBar.styles';

interface Props {
  left: number;
  right: number;
  flipped?: boolean;
  title: string;
  side?: boolean;
}

export default function DistributionBar({
  left,
  right,
  flipped = false,
  title,
  side = true,
}: Props): ReactElement {
  return (
    <DistributionBarWrapper flipped={flipped}>
      {!flipped && (
        <DistributionBarHeader flipped={flipped}>
          <span className="text-xs font-medium">{side ? 'Visiting' : 'Over'}</span>
          <span className="text-xs font-medium">{side ? 'Home' : 'Under'}</span>
        </DistributionBarHeader>
      )}
      <DistributionBarHeader flipped={flipped}>
        <DistributionBarNumber>{left ? left + '%' : 'N/A'}</DistributionBarNumber>
        <DistributionBarTitle>{title}</DistributionBarTitle>
        <DistributionBarNumber>{right ? right + '%' : 'N/A'}</DistributionBarNumber>
      </DistributionBarHeader>
      <DistributionColorBar>
        <ColorBarPart width={left} highlight={left >= right} />
        <ColorBarPart width={right} highlight={left < right} />
      </DistributionColorBar>
    </DistributionBarWrapper>
  );
}
