import { ReactElement } from 'react';

import FullLayout from 'components/layouts/full';
import useAuth from 'common/auth/useAuth';
import { startSportAnalysis } from 'common/api/generic/genericGameAnalysis';

const sports = ['mlb', 'nba', 'ncaab', 'ncaaf', 'nfl', 'nhl'];

export default function Data(): ReactElement {
  const auth = useAuth();

  function startAnalysis(sport: string) {
    startSportAnalysis(sport, auth.user);
  }

  return (
    <FullLayout className="bg-gray-100">
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-lg mt-5">
          <h2 className="text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:truncate">
            Data
          </h2>
        </div>

        <div className="hidden sm:block max-w-screen-lg w-full" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="flex justify-start w-full max-w-screen-lg">
          {sports.map((sport) => (
            <button
              key={sport}
              onClick={() => {
                startAnalysis(sport);
              }}
              className="mr-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {sport.toUpperCase()}
            </button>
          ))}
        </div>
      </div>
    </FullLayout>
  );
}
