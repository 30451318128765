import { ReactElement } from 'react';

import UserSettingsForm from 'components/forms/usersettingsform';
import NewPasswordForm from 'components/forms/newpasswordform';
import SubscriptionPanel from '../subscriptionpanel';

export default function ProfilePanel(): ReactElement {
  return (
    <div className="flex items-center flex-col">
      <div className="flex-1 min-w-0 w-full max-w-screen-lg mt-5 hidden md:block">
        <h2 className="text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:truncate">
          Profile
        </h2>
      </div>

      <div className="hidden sm:block max-w-screen-lg w-full" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <UserSettingsForm />

      <div className="hidden sm:block max-w-screen-lg w-full" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <NewPasswordForm />

      <div className="hidden sm:block max-w-screen-lg w-full" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <SubscriptionPanel />
    </div>
  );
}
