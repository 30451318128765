import { ReactElement, useEffect, useState } from 'react';
import useAuth from 'common/auth/useAuth';

import { getTop25 } from 'common/api/sports/ncaab/rankDelta';
import { DeltaList, DeltaListData } from 'models/deltalist';

import Table from 'components/modules/table';
import FullLayout from 'components/layouts/full';

const TableColumns = [
  { columnHeader: 'AP Rank', columnId: 'ap_rank' },
  { columnHeader: 'Team', columnId: 'team_name' },
  { columnHeader: 'SIC Value', columnId: 'sic_value', colorStat: true, alignRight: true },
  {
    columnHeader: 'Rating',
    columnId: 'sic_value_description',
    alignRight: true,
    prohibitSort: true,
  },
];

export default function NcaabTop25(): ReactElement {
  const auth = useAuth();

  const [data, setData] = useState<Array<DeltaListData>>();
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getTop25Data();
  }, []);

  async function getTop25Data() {
    if (auth.user) {
      setTableLoading(true);
      const rdl = await getTop25(auth.user);

      rdl.data.then((data: DeltaList) => {
        setData(data.delta);
        setTableLoading(false);
      });
    }
  }
  JSON.stringify(data);

  return (
    <FullLayout>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-lg mt-5">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mb-5 leading-normal">
            NCAAB Top 25
          </h2>
          <Table
            classNames="w-full max-w-screen-lg"
            columns={TableColumns}
            items={data}
            idProp="user_email"
            showLoader={tableLoading}
          ></Table>
        </div>
      </div>
    </FullLayout>
  );
}
