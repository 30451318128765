import { Game } from 'models/game';
import { User } from 'models/user';
import { authorizedApiCall } from '../apiCall';

export async function startSportAnalysis(sport: string, user?: User): Promise<Array<Game>> {
  if (!user) {
    return [];
  }

  const options = {
    method: 'GET',
  };

  const res = await authorizedApiCall(`/analysis?sport=${sport}`, options, user);

  return res.data;
}
