import useAuth from 'common/auth/useAuth';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { md5 } from 'hash-wasm';

import { Sports, SubscriptionLevels } from 'models/enums';

import { LogoutIcon, ChevronDownIcon, UserIcon } from '@heroicons/react/outline';

import {
  NavWrapper,
  NavHeader,
  Profile,
  ProfileImage,
  ProfileName,
  Navigation,
  NavItem,
  NavFooter,
  NavVersion,
  NavHelpLink,
  NavItemToggle,
  NavSubItem,
} from './Nav.styles';
import useSub from 'common/provider/SubscriptionProvider';
import useSettings from 'common/provider/SettingsProvider';
import Switch from 'components/elements/switch';

export default function Nav(): ReactElement {
  const location = useLocation();
  const [userGravatarHash, setUserGravatarHash] = useState('');

  const [itemsOpen, setItemsOpen] = useState<string[]>([]);
  const auth = useAuth();
  const sub = useSub();
  const settings = useSettings();

  const navItems = [
    {
      title: 'Sports',
      items: [
        { to: '/', title: 'Dashboard', children: [], display: true },
        {
          key: 'mlb',
          to: '/mlb',
          title: 'MLB',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.MLB.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/mlb',
              title: 'Games',
              display: true,
            },
          ],
        },
        {
          key: 'ncaaf',
          to: '/ncaaf',
          title: 'NCAAF',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NCAAF.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/ncaaf',
              title: 'Games',
              display: true,
            },
          ],
        },
        {
          key: 'nfl',
          to: '/nfl',
          title: 'NFL',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NFL.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/nfl',
              title: 'Games',
              display: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Off-Season',
      display: auth.user && (sub.ALL.level == SubscriptionLevels.PRO || auth.user.user_level >= 4),
      items: [
        {
          key: 'nba',
          to: '/nba',
          title: 'NBA',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NBA.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/nba',
              title: 'Games',
              display: true,
            },
          ],
        },
        {
          key: 'ncaab',
          to: '/ncaab',
          title: 'NCAAB',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NCAAB.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/ncaab',
              title: 'Games',
              display: true,
            },
            {
              to: '/ncaab/ranks',
              title: 'Rank Deltas',
              display: auth.user?.user_level && 5 <= auth.user?.user_level,
            },
            {
              to: '/ncaab/top-25',
              title: 'Top 25',
              display: true,
            },
          ],
        },
        {
          key: 'nhl',
          to: '/nhl',
          title: 'NHL',
          display:
            auth.user &&
            (sub.ALL.level == SubscriptionLevels.PRO ||
              sub.NHL.level == SubscriptionLevels.PRO ||
              auth.user.user_level >= 4),
          children: [
            {
              to: '/nhl',
              title: 'Games',
              display: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Tutorials & FAQ',
      display: auth.user && auth.user.user_level >= 5,
      items: [
        {
          to: '/tutorials',
          title: 'SIC Tutorials',
          children: [
            {
              to: '/tutorials',
              title: "Guides and How-To's",
              display: true,
            },
          ],
          display: true,
        },
      ],
    },
    {
      title: 'Administration',
      display: auth.user && auth.user.user_level >= 5,
      items: [
        { to: '/invite', title: 'Invite', children: [], display: true },
        { to: '/status', title: 'Status Dashboard', children: [], display: true },
        {
          to: '/users',
          title: 'Users',
          display: auth.user?.user_level && 10 <= auth.user?.user_level,
          children: [],
        },
        {
          to: '/data',
          title: 'Data',
          display: auth.user?.user_level && 10 <= auth.user?.user_level,
          children: [],
        },
      ],
    },
  ];

  useEffect(() => {
    getUserGravatarHash();
  }, []);

  function toggleItem(item: string) {
    itemsOpen.includes(item)
      ? setItemsOpen(itemsOpen.filter((i) => i === item))
      : setItemsOpen([...itemsOpen, item]);
  }

  async function getUserGravatarHash() {
    if (auth.user) {
      await md5(auth.user.user_email.toLowerCase()).then((data) => {
        setUserGravatarHash(data);
      });
    }
  }

  return (
    <NavWrapper className="hidden md:flex relative">
      <NavHeader>
        <Profile to={'/profile'}>
          <ProfileImage url={`https://www.gravatar.com/avatar/${userGravatarHash}`} />
          <div className="flex flex-wrap">
            {auth.user && auth.user.subscriptions.find((sub) => sub.sport_id == Sports.ALL) && (
              <>
                <span className="min-w-full h-0 pt-1">&nbsp;</span>
                <div
                  className={`mb-0.5 text-xxs text-white px-1 py-0.5 rounded-sm ${
                    sub.ALL.level == SubscriptionLevels.PRO ? 'bg-orange-700' : 'bg-sky-700'
                  }`}
                >
                  {sub.ALL.level == SubscriptionLevels.PRO ? 'PRO' : 'LITE'}
                </div>
              </>
            )}
            <ProfileName>{auth.user?.username}</ProfileName>
          </div>
        </Profile>

        <div className="flex">
          <Link to={'/profile'}>
            <UserIcon className="h-5 w-5 mr-4 text-white cursor-pointer" />
          </Link>

          <LogoutIcon
            className="h-5 w-5 text-white cursor-pointer"
            onClick={() => {
              auth.logout(auth.user);
            }}
          />
        </div>
      </NavHeader>
      <Navigation>
        {navItems.map((section) => {
          return (
            section.display !== false && (
              <>
                <h3 key={section.title} className="text-gray-400 text-xs ml-3 mb-2 mt-4">
                  {section.title}
                </h3>
                {section.items.map((item) => {
                  return (
                    item.display !== false && (
                      <NavItemToggle key={section.title + item.title} open={false}>
                        <NavItem
                          key={item.to + item.title}
                          to={item.to}
                          isCurrent={
                            location.pathname === item.to ||
                            (item.children &&
                              item.children.length > 0 &&
                              location.pathname.includes(item.to))
                          }
                          className="flex justify-between"
                        >
                          <div className="flex items-center">
                            {item.title}
                            {item.children ? (
                              <span
                                className={`${
                                  !location.pathname.includes(item.to) &&
                                  item.to !== location.pathname
                                    ? 'hidden'
                                    : ''
                                } text-xs ml-2 text-gray-300`}
                              >
                                {item.children &&
                                  item.children.length > 0 &&
                                  item.children.find((obj) => obj.to === location.pathname) && (
                                    <>
                                      <span className="mr-1">-</span>
                                      <span className="ml-1">
                                        {
                                          item.children.find((obj) => obj.to === location.pathname)
                                            ?.title
                                        }
                                      </span>
                                    </>
                                  )}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          {item.children &&
                            item.children.filter((i) => {
                              return i.display !== false;
                            }).length > 1 && (
                              <ChevronDownIcon
                                className="h-5 w-5 text-white cursor-pointer"
                                onClick={() => toggleItem(item?.to)}
                              />
                            )}
                        </NavItem>
                        {item.children &&
                          item.children.filter((i) => {
                            return i.display !== false;
                          }).length > 1 &&
                          item.children?.map((subItem) => {
                            return (
                              subItem.display !== false && (
                                <NavSubItem
                                  key={subItem.to + subItem.title}
                                  to={subItem.to}
                                  isCurrent={location.pathname == subItem.to}
                                >
                                  {subItem.title}
                                </NavSubItem>
                              )
                            );
                          })}
                      </NavItemToggle>
                    )
                  );
                })}
              </>
            )
          );
        })}
      </Navigation>
      <NavFooter>
        <NavHelpLink href="mailto:help@sportsinvestingclub.com">Need help?</NavHelpLink>
        <div className="flex justify-center mb-3">
          <NavHelpLink target="_blank" href="https://sportsinvestingclub.com/terms-of-service">
            Terms of Service
          </NavHelpLink>
          <NavHelpLink target="_blank" href="https://sportsinvestingclub.com/privacy">
            Privacy Policy
          </NavHelpLink>
        </div>
        <NavVersion>v1.5.3</NavVersion>
      </NavFooter>

      {auth.user && auth.user.user_level >= 5 ? (
        <>
          <div className="h-12 min-h-12"></div>
          <div className="bg-gray-900 fixed w-80 -mx-4 px-7 py-4 bottom-0 left-4 shadow">
            <div className="flex items-center">
              <Switch
                enabled={settings.editorMode}
                theme="dark"
                onClick={() => settings.toggleEditorMode()}
              />
              <span className="ml-3 font-medium text-white text-sm mb-0.5">
                Editor Mode - {settings.editorMode ? 'ON' : 'OFF'}
              </span>
            </div>
          </div>
        </>
      ) : null}
    </NavWrapper>
  );
}
