import RegisterMask from 'components/features/register';
import { ReactElement } from 'react';

import AuthLayout from 'components/layouts/auth';

export default function RegisterPage(): ReactElement {
  return (
    <AuthLayout>
      <RegisterMask />
    </AuthLayout>
  );
}
