import { ReactElement } from 'react';

import { LogoText } from './Logo.styles';

interface Props {
  light: boolean;
  className?: string;
}

export default function Logo({ light, className }: Props): ReactElement {
  return (
    <LogoText className={className} light={light}>
      SIC
    </LogoText>
  );
}
