import { ReactElement } from 'react';

interface Props {
  enabled: boolean;
  theme?: string;
  onClick: (enabled: boolean) => void;
}

export default function Switch({ enabled, onClick, theme = 'light' }: Props): ReactElement {
  return (
    <>
      {theme == 'light' ? (
        <div
          onClick={() => onClick(!enabled)}
          className={`
      ${
        enabled ? 'bg-indigo-600' : 'bg-gray-200'
      } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            aria-hidden="true"
            className={`${
              enabled ? 'translate-x-5' : 'translate-x-0'
            } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
          />
        </div>
      ) : (
        <div
          onClick={() => onClick(!enabled)}
          className={`
        ${
          enabled ? 'bg-indigo-600' : 'bg-gray-800'
        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            aria-hidden="true"
            className={`${
              enabled ? 'translate-x-5 bg-gray-200' : 'translate-x-0 bg-gray-400'
            } pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out`}
          />
        </div>
      )}
    </>
  );
}
