import { User } from 'models/user';
import { getCurrentGameData, getGameData } from '../../generic/genericDownload';

export async function getNhlGameData(user?: User) {
  getGameData('nhl', user);
}

export async function getCurrentNhlGameData(user?: User) {
  getCurrentGameData('nhl', user);
}
