import { getCustomerPortalSession } from 'common/api/subscription/session';
import useAuth from 'common/auth/useAuth';
import useSub from 'common/provider/SubscriptionProvider';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

export function SubscriptionPanel(): ReactElement {
  const auth = useAuth();
  const sub = useSub();
  const [loadingNavigation, setLoadingNavigation] = useState(false);

  async function openCustomerPortal() {
    setLoadingNavigation(true);
    const session = await getCustomerPortalSession(auth.user);
    window.location.href = session.url;
  }

  return (
    <div className="max-w-screen-lg w-full bg-white p-5 shadow sm:rounded-md">
      {sub.ALL.active && (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            You&apos;re currently on the{' '}
            <div
              className={`mx-1 mt-0.5 text-xs text-white px-1 py-0.5 rounded-sm ${
                sub.ALL.level == 1 ? 'bg-sky-700' : 'bg-orange-700'
              }`}
            >
              {sub.ALL.level == 1 ? 'SIC Lite' : 'SIC PRO'}
            </div>{' '}
            plan.
          </div>
          <button
            onClick={openCustomerPortal}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={loadingNavigation}
          >
            Manage your subscription
          </button>
        </div>
      )}

      {!sub.ALL.active &&
        auth.user &&
        auth.user.customer_id &&
        auth.user.user_level == 1 &&
        !auth.user.free_trial_eligible && (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">Your subscription has expired.</div>
            <button
              onClick={openCustomerPortal}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loadingNavigation}
            >
              Re-join SIC now
            </button>
          </div>
        )}

      {!sub.ALL.active && auth.user?.user_level && auth.user.user_level > 1 && (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            You do not have an active SIC subscription, but someone invited you - enjoy{' '}
            <div
              className={`mx-1 mt-0.5 text-xs text-white px-1 py-0.5 rounded-sm ${
                auth.user.user_level > 3 ? 'bg-orange-700' : 'bg-sky-700'
              }`}
            >
              {auth.user.user_level > 3 ? 'SIC PRO' : 'SIC Lite'}
            </div>{' '}
            for free!
          </div>
        </div>
      )}

      {!sub.ALL.active &&
        auth.user?.user_level &&
        auth.user.user_level == 1 &&
        (!auth.user.customer_id || auth.user.free_trial_eligible) && (
          <div className="flex items-center justify-between w-full">
            <span>You do not have an active SIC subscription.</span>
            <Link
              to={'/'}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Check out our pricing plans
            </Link>
          </div>
        )}
    </div>
  );
}
