import { User } from 'models/user';
import { getCurrentGameData, getGameData } from '../../generic/genericDownload';

export async function getNflGameData(user?: User) {
  getGameData('nfl', user);
}

export async function getCurrentNflGameData(user?: User) {
  getCurrentGameData('nfl', user);
}
