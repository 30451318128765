import useAuth from 'common/auth/useAuth';
import { TailwindTextInput } from 'components/elements/textinput';
import { ReactElement, useEffect, useState } from 'react';

import { createInvite, deleteInvite, getInvites } from 'common/auth/invites';
import { Invite } from 'models/invite';

import FormSection from 'components/modules/formsection';
import Table from 'components/modules/table/Table';

const TableColumns = [
  { columnHeader: 'Email', columnId: 'user_email' },
  { columnHeader: 'Invite Hash', columnId: 'invite_hash', copy: true },
  { columnHeader: 'User Level', columnId: 'invite_level' },
  { columnHeader: '', columnId: '', delete: true, prohibitSort: true },
];

export default function InvitePanel(): ReactElement {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [inviteLevel, setInviteLevel] = useState('1');
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [invites, setInvites] = useState<Array<Invite>>([]);
  const [tableLoading, setTableLoading] = useState(false);

  const auth = useAuth();

  async function handleInvite() {
    if (auth.user) {
      createInvite(email, inviteLevel, auth.user).then((data) => {
        if (data.invite_hash !== '0') {
          setError(false);
          setLoading(false);
          setEmail('');
          setInviteLevel('1');
          loadInvites();
        } else {
          setError(true);
        }
      });
    }
  }

  async function loadInvites() {
    setTableLoading(true);
    getInvites(auth.user).then((data) => {
      setInvites(data);
      setTableLoading(false);
    });
  }

  async function handleDelete(e: Invite) {
    deleteInvite(auth.user, e).then((data) => {
      setInvites(data);
    });
  }

  const handleKeypress = (event: any) => {
    if (event.key == 'Enter' && !error) {
      handleInvite();
    }
  };

  useEffect(() => {
    loadInvites();
  }, []);

  useEffect(() => {
    setCanSubmit(!!email.trim());
  }, [email]);

  return (
    <>
      <div className="flex items-center flex-col">
        <div className="flex-1 min-w-0 w-full max-w-screen-lg mt-5">
          <h2 className="text-2xl font-bold leading-normal text-gray-900 sm:text-3xl sm:truncate">
            Invite
          </h2>
        </div>

        <div className="hidden sm:block max-w-screen-lg w-full" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <FormSection
          sectionHeader="Invite New Users"
          sectionFlavour="Here you can invite new users to SIC."
        >
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <TailwindTextInput
                className="col-span-6 sm:col-span-4"
                id="email"
                placeholder="user@invite.com"
                label="Email"
                error={error}
                value={email}
                errormessage="Email already registered/invited"
                handleKeypress={handleKeypress}
                handleChange={(event) => {
                  setError(false);
                  setEmail(event.target.value);
                }}
              />
              <div className="col-span-2">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                  Role
                </label>
                <select
                  id="location"
                  name="location"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  defaultValue="1"
                  onChange={(v) => {
                    setInviteLevel(v.target.value);
                  }}
                  value={inviteLevel}
                >
                  <option value={1}>User</option>
                  <option value={3}>Friend</option>
                  <option value={4}>PRO Friend</option>
                </select>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              onClick={() => {
                setLoading(true);
                handleInvite();
              }}
              disabled={!canSubmit || loading}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Invite
            </button>
          </div>
        </FormSection>
        <Table
          classNames="w-full max-w-screen-lg"
          columns={TableColumns}
          items={invites}
          idProp="user_email"
          showLoader={tableLoading}
          onDelete={(e) => {
            handleDelete(e);
          }}
        ></Table>
      </div>
    </>
  );
}
