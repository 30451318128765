import styled from '@emotion/styled';

interface LoaderProps {
  theme: string;
}

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const LoaderWrapper = styled.div<LoaderProps>`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  div {
    position: absolute;
    top: 17px;
    width: 6px;
    height: 6px;
    border-radius: 4px;
    background: ${(props) => (props.theme == 'white' ? 'white' : 'var(--color-400)')};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-of-type(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-of-type(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-of-type(3) {
      left: 20px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-of-type(4) {
      left: 32px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }
`;

export const LargeLoaderWrapper = styled.div<LoaderProps>`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${(props) => (props.theme == 'white' ? 'white' : 'var(--color-400)')};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-of-type(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-of-type(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-of-type(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-of-type(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
