import styled from '@emotion/styled';

export const GamesPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75rem;
`;

export const GamesPanelList = styled.div`
  display: flex;
  flex-direction: column;
`;
