import { apiCall } from 'common/api/apiCall';
import { res } from 'components/modules/types';

export async function requestReset(reset_email: string): Promise<res> {
  const options = {
    method: 'POST',
  };

  const fetchString = `/reset?reset_email=${reset_email}`;
  const response = await apiCall(fetchString, options);

  return response;
}

export async function verifyResetHash(resetHash: string): Promise<res> {
  const options = {
    method: 'GET',
  };

  const fetchString = `/reset?reset_hash=${resetHash}`;
  const response = await apiCall(fetchString, options);

  return response;
}

export async function resetPassword(resetHash: string, newPassword: string): Promise<res> {
  const options = {
    method: 'POST',
  };

  const fetchString = `/reset?reset_hash=${encodeURIComponent(
    resetHash
  )}&new_password=${encodeURIComponent(newPassword)}`;
  const response = await apiCall(fetchString, options);

  return response;
}
