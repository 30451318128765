import { ReactElement } from 'react';

import { LayoutWrapper, LayoutContent } from './FullLayout.styles';
import Nav from 'components/features/nav';
import MobileNav from 'components/features/mobilenav';

interface Props {
  className?: string;
  children?: ReactElement | ReactElement[];
}

export default function FullLayout({ className = 'bg-gray-100', children }: Props): ReactElement {
  return (
    <LayoutWrapper className={className + ' flex-col md:flex-row'}>
      <Nav />
      <MobileNav />
      <LayoutContent className="px-4 pb-4 md:p-8">{children}</LayoutContent>
    </LayoutWrapper>
  );
}
