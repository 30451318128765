import { Game } from 'models/game';
import { ReactElement, useEffect, useState } from 'react';
import {
  PickCardHeader,
  PickCardHeaderCol,
  PickCardNote,
  PickCardPitchers,
  PickCardScore,
  PickCardScoreTeam,
  PickCardTeam,
  PickCardTimeData,
} from './PickCard.styles';

interface Props {
  game: Game;
  className?: string;
  spreadSport?: boolean;
  fillNss?: boolean;
}

export default function PickCard({
  game,
  className,
  spreadSport = true,
  fillNss = true,
}: Props): ReactElement {
  const [over, setOver] = useState('');
  const [under, setUnder] = useState('');
  const [cleanOverUnder, setcleanOverUnder] = useState(0);
  const [overMoneyline, setOverMoneyline] = useState<number>();
  const [underMoneyline, setUnderMoneyline] = useState<number>();

  const [homePitcherString, setHomePitcherString] = useState<string>();
  const [visitingPitcherString, setVisitingPitcherString] = useState<string>();

  function formatDate(date: string): string {
    const cdate = new Date(date);
    const timeString = cdate.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      timeStyle: 'short',
    });

    const dateString = cdate.toLocaleString('en-US', {
      timeZone: 'America/New_York',
      dateStyle: 'short',
    });

    const timezoneString = cdate
      .toLocaleString('en-US', {
        timeZone: 'America/New_York',
        timeZoneName: 'short',
      })
      .split(' ');

    return `${dateString} ${timeString} ${timezoneString[timezoneString.length - 1]}`;
  }

  useEffect(() => {
    if (game.odds[0]?.over) {
      setOver('o' + game.odds[0].over);
      setUnder('u' + game.odds[0].over);
      setcleanOverUnder(game.odds[0].over);
      setOverMoneyline(game.odds[0].over_line);

      let counterML = -220 - game.odds[0].over_line;
      if (counterML > -100) {
        counterML = 200 + counterML;
      }
      setUnderMoneyline(counterML);
    } else if (game.odds[0]?.under) {
      setOver('o' + game.odds[0].under);
      setUnder('u' + game.odds[0].under);
      setcleanOverUnder(game.odds[0].under);
      setUnderMoneyline(game.odds[0].under_line);

      let counterML = -220 - game.odds[0].under_line;
      if (counterML > -100) {
        counterML = 200 + counterML;
      }
      setOverMoneyline(counterML);
    }

    if (game.home_starting_pitcher && game.visiting_starting_pitcher) {
      const home_pitcher = `${game.home_starting_pitcher} ${
        game.home_starting_pitcher_left_handed ? '(L)' : '(R)'
      }`;
      const visisting_pitcher = `${game.visiting_starting_pitcher} ${
        game.visiting_starting_pitcher_left_handed ? '(L)' : '(R)'
      }`;
      setHomePitcherString(home_pitcher);
      setVisitingPitcherString(visisting_pitcher);
    }
  }, [game]);

  return (
    <div className={`${className} border-b p-2 pb-3 overflow-hidden bg-white w-100`}>
      <PickCardHeader
        className={`flex-col md:flex-row overflow-hidden ${
          game.highlighted ? 'bg-indigo-700' : 'bg-gray-800'
        }`}
      >
        <PickCardHeaderCol className="w-full md:w-auto mb-2 md:mb-0">
          <PickCardTimeData>
            <span>{formatDate(game.date)}</span>
          </PickCardTimeData>
          <PickCardTeam>
            <span>{game.visiting_name}</span>
          </PickCardTeam>
          <span> VS </span>
          <PickCardTeam>
            <span>{game.home_name}</span>
          </PickCardTeam>
          {visitingPitcherString && homePitcherString ? (
            <PickCardPitchers>
              <span>{`${visitingPitcherString} - ${homePitcherString}`}</span>
            </PickCardPitchers>
          ) : (
            ''
          )}
        </PickCardHeaderCol>
        <PickCardHeaderCol className="flex md:block justify-end w-full md:w-auto">
          {game.highlighted ? (
            <>
              {['both', 'sides'].includes(game.highlighted_play) && (
                <PickCardScore className="mt-2 md:mt-0" complete={true}>
                  <b>Highlight Play</b> on{' '}
                  <PickCardScoreTeam>
                    {game.highlighted_side == 'home'
                      ? game.home_name +
                        ' ' +
                        (spreadSport
                          ? game.odds[0].home_odds_points
                          : game.odds[0].home_odds_moneyline)
                      : game.visiting_name +
                        ' ' +
                        (spreadSport
                          ? game.odds[0].visiting_odds_points
                          : game.odds[0].visiting_odds_moneyline)}
                  </PickCardScoreTeam>
                </PickCardScore>
              )}
              {['both', 'total'].includes(game.highlighted_play) && (
                <PickCardScore className="mt-2 md:mt-0" complete={true}>
                  <b>Highlight Play</b> on{' '}
                  <PickCardScoreTeam>
                    {game.highlighted_over_under == 'over'
                      ? `o${cleanOverUnder}`
                      : `u${cleanOverUnder}`}
                  </PickCardScoreTeam>
                </PickCardScore>
              )}
            </>
          ) : (
            <>
              <PickCardScore complete={game.score.score_complete}>
                <b>{game.score.score}</b> on{' '}
                {game.score.score == 'No Play' ? (
                  <span>Sides</span>
                ) : (
                  <PickCardScoreTeam>
                    {game.score.score_home ? game.home_name : game.visiting_name}
                  </PickCardScoreTeam>
                )}
              </PickCardScore>
              <PickCardScore complete={game.score.ou_score_complete}>
                <b>{game.score.ou_score}</b> on{' '}
                {game.score.ou_score == 'No Play' ? (
                  <span>Total</span>
                ) : (
                  <PickCardScoreTeam>
                    {game.score.ou_score_under ? `u${cleanOverUnder}` : `o${cleanOverUnder}`}
                  </PickCardScoreTeam>
                )}
              </PickCardScore>
            </>
          )}
        </PickCardHeaderCol>
      </PickCardHeader>

      <div className="flex flex-1 flex-col px-2 pt-2">
        <div className="w-full flex mb-3">
          {game.NSS && (
            <span className="text-white mr-2 text-sm">
              {fillNss ? ('00' + parseInt(game.NSS)).slice(-3) : parseInt(game.NSS)}
            </span>
          )}
          <span className={`flex-1 font-bold text-xs`}>Team</span>
          <span className="w-1/5 font-bold text-xs text-center">Spread</span>
          <span className="w-1/5 font-bold text-xs text-center">Moneyline</span>
          <span className="w-1/5 font-bold text-xs text-center">Total</span>
        </div>

        <div className="w-full flex mb-1 items-center">
          {game.NSS && (
            <span className="text-sm mr-2">
              {fillNss ? ('00' + parseInt(game.NSS)).slice(-3) : parseInt(game.NSS)}
            </span>
          )}
          <span className="flex-1">{game.visiting_name}</span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              spreadSport &&
              !game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play'
                ? 'font-bold'
                : ''
            }`}
          >
            <span>
              {game.odds[0]?.visiting_odds_points == 0 ? 'PK' : game.odds[0]?.visiting_odds_points}
            </span>
            <span className="text-xs md:ml-1">{game.odds[0]?.visiting_odds_points_moneyline}</span>
          </span>
          <span
            className={`w-1/5 text-center ${
              !spreadSport &&
              !game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play'
                ? 'font-bold'
                : ''
            }`}
          >
            {game.odds[0]?.visiting_odds_moneyline}
          </span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              !game.score.ou_score_under &&
              game.score.ou_score_complete &&
              game.score.ou_score !== 'No Play'
                ? 'font-bold'
                : ''
            }`}
          >
            <span>{over}</span>
            <span className="text-xs md:ml-1">{overMoneyline}</span>
          </span>
        </div>

        <div className="w-full flex items-center">
          {game.NSS && (
            <span className="text-sm mr-2">
              {fillNss ? ('00' + (parseInt(game.NSS) + 1)).slice(-3) : parseInt(game.NSS) + 1}
            </span>
          )}
          <span className="flex-1">{game.home_name}</span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              spreadSport &&
              game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play'
                ? 'font-bold'
                : ''
            }`}
          >
            <span>
              {game.odds[0]?.home_odds_points == 0 ? 'PK' : game.odds[0]?.home_odds_points}
            </span>
            <span className="text-xs md:ml-1">{game.odds[0]?.home_odds_points_moneyline}</span>
          </span>
          <span
            className={`w-1/5 text-center ${
              !spreadSport &&
              game.score.score_home &&
              game.score.score_complete &&
              game.score.score !== 'No Play'
                ? 'font-bold'
                : ''
            }`}
          >
            {game.odds[0]?.home_odds_moneyline}
          </span>
          <span
            className={`w-1/5 text-center flex flex-col md:flex-row items-center justify-center ${
              game.score.ou_score_under &&
              game.score.ou_score_complete &&
              game.score.ou_score !== 'No Play'
                ? 'font-bold'
                : ''
            }`}
          >
            <span>{under}</span>
            <span className="text-xs md:ml-1">{underMoneyline}</span>
          </span>
        </div>
      </div>
      <PickCardNote>{formatDate(game.odds[0]?.data_time)}</PickCardNote>
      {game.highlighted && game.highlighted_comment ? (
        <div className="w-full overflow-hidden -mb-3 -mx-2 pl-4 pt-2 pb-2.5 mt-2 rounded-b-md bg-gray-800 box-content">
          <div className="text-white">
            <span className="text-sm font-medium">{game.highlighted_comment}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
