import LoginMask from 'components/features/login';
import { ReactElement } from 'react';

import AuthLayout from 'components/layouts/auth';

export function LoginPage(): ReactElement {
  return (
    <AuthLayout>
      <LoginMask />
    </AuthLayout>
  );
}
