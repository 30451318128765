export enum Sports {
  ALL = 1,
  MLB = 2,
  NBA = 3,
  NCAAB = 4,
  NCAAF = 5,
  NFL = 6,
  NHL = 7,
}

export const SportsNames = {
  1: 'All Sports',
  2: 'MLB',
  3: 'NBA',
  4: 'NCAAB',
  5: 'NCAAF',
  6: 'NFL',
  7: 'NHL',
};

export enum SubscriptionLevels {
  LITE = 1,
  PRO = 2,
}
