import { User } from 'models/user';
import { getCurrentGameData, getGameData } from '../../generic/genericDownload';

export async function getNcaafGameData(user?: User) {
  getGameData('ncaaf', user);
}

export async function getCurrentNcaafGameData(user?: User) {
  getCurrentGameData('ncaaf', user);
}
